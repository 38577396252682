import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Grid, CircularProgress, Backdrop } from '@mui/material';
import Swal from 'sweetalert2';
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import { Card } from "@mui/material";
import { Tooltip, IconButton } from '@mui/material';
import { HelpCenterRounded, Add, Remove } from '@mui/icons-material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import CustomSelect from 'assets/theme/components/Select/CustomSelect';
import * as AiIcons from "react-icons/ai";
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';

export const AddCourses = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const sessiondetails = JSON.parse(localStorage.getItem("sessiondetails"));
    const name = sessiondetails.userName;

    const [errors, setErrors] = useState({});
    const [subjects, setSubjects] = useState(['']);
    const [formData, setFormData] = useState({
        admin_id: name,
        course_description: "",
        course_duration: "",
        course_fees: "",
        course_id: "",
        courseName: "",
        course_sub_type: "",
        course_type: "",
        image_url: "",
        org: org,
        syallabus: "",
        gradeSubjects: [],
        createdBy: name,
        created_date_time: "",
        updatedBy: "",
        updated_date_time: ""
    });
    const [selectedImage, setSelectedImage] = useState(null);
    const [fetchingDescription, setFetchingDescription] = useState(false);

    const fetchDescription = async (courseName) => {
        if (!courseName?.trim()) {
            return;
        }

        setFetchingDescription(true);

        try {
            const prompt = `give only one description more than two lines to add a course about ${courseName}`;
            const encodedQuestion = encodeURIComponent(prompt);

            const response = await axios({
                method: 'POST',
                url: `${APIData.tpapi}openai/content?question=${encodedQuestion}`,
                headers: APIData.tpheaders
            });

            const description = response.data;

            setFormData(prev => ({
                ...prev,
                course_description: description.trim()
            }));

        } catch (error) {
            console.error("Error fetching AI description:", error);
            await Swal.fire({
                icon: "error",
                title: "Failed to Fetch Description",
                text: error.response?.data?.message || "An error occurred while generating the description. You can enter it manually.",
                confirmButtonText: "OK",
            });
        } finally {
            setFetchingDescription(false);
        }
    };

    const courseTypeOptions = [
        { value: 'PROFESSIONAL', label: 'PROFESSIONAL' },
        { value: 'ACADEMICS', label: 'ACADEMICS' }
    ];

    const courseSubTypeOptions = [
        { value: 'STATE', label: 'STATE' },
        { value: 'ICSE', label: 'ICSE' },
        { value: 'CBSE', label: 'CBSE' },
        { value: 'PROFESSIONAL', label: 'PROFESSIONAL' }
    ];
    const getErrorHelperText = (fieldName) => {
        return errors[fieldName] ? (
            <SoftTypography variant="caption" color="error" style={{ marginTop: '4px', display: 'block' }}>
                {errors[fieldName]}
            </SoftTypography>
        ) : null;
    };

    const handleSubjectChange = (index, value) => {
        const newSubjects = [...subjects];
        newSubjects[index] = value;
        setSubjects(newSubjects);

        setFormData(prev => ({
            ...prev,
            gradeSubjects: newSubjects.filter(subject => subject.trim() !== '')
        }));

        if (value.trim() !== '') {
            setErrors(prev => ({ ...prev, gradeSubjects: '' }));
        }
    };

    const addSubjectField = () => {
        setSubjects([...subjects, '']);
    };

    const removeSubjectField = (index) => {
        if (subjects.length > 1) {
            const newSubjects = subjects.filter((_, i) => i !== index);
            setSubjects(newSubjects);
            setFormData(prev => ({
                ...prev,
                gradeSubjects: newSubjects.filter(subject => subject.trim() !== '')
            }));
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }

        // Fetch description when course name is entered and has at least 3 characters
        if (name === 'courseName' && value.length >= 3) {
            fetchDescription(value);
        }
    };

    const handleSelectChange = (selectedOption, actionMeta) => {
        const name = actionMeta.name;
        const value = selectedOption ? selectedOption.value : '';

        setFormData(prev => {
            // Clear gradeSubjects when switching away from ACADEMICS
            if (name === 'course_type') {
                return {
                    ...prev,
                    [name]: value,
                    // Only keep gradeSubjects if course type is ACADEMICS
                    gradeSubjects: value === 'ACADEMICS' ? prev.gradeSubjects : []
                };
            }
            return {
                ...prev,
                [name]: value,
            };
        });

        if (errors[name]) {
            setErrors(prev => ({ ...prev, [name]: '' }));
        }
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (file.size > 1000000) {
                setErrors(prev => ({
                    ...prev,
                    image_url: "File too large. Max file size is 1MB."
                }));
                setSelectedImage(null);
                return;
            }
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
                setFormData(prev => ({
                    ...prev,
                    image_url: reader.result.split(',')[1]
                }));
                setErrors(prev => ({ ...prev, image_url: '' }));
            };
            reader.readAsDataURL(file);
        }
    };

    const validateForm = () => {
        const newErrors = {};
        if (formData.course_type === 'ACADEMICS') {
            // Validate grade name format
            const gradePattern = /^Grade-(0[1-9]|1[0-2])$/;
            if (!gradePattern.test(formData.courseName)) {
                newErrors.courseName = "For academics, use format 'Grade-01' to 'Grade-12'";
            }

            // Validate subjects
            if (!formData.gradeSubjects || formData.gradeSubjects.length === 0) {
                newErrors.gradeSubjects = "At least one subject is required for academic courses";
            }
        }

        // Course description validation
        if (!formData.course_description.trim()) {
            newErrors.course_description = "Course description is required";
        } else if (formData.course_description.trim().length < 5) {
            newErrors.course_description = "Description must be at least 10 characters";
        }

        // Course duration validation
        if (!formData.course_duration.trim()) {
            newErrors.course_duration = "Course duration is required";
        } else {
            const durationPattern = /^.*?(month|year)s?$/i;
            if (!durationPattern.test(formData.course_duration)) {
                newErrors.course_duration = "Duration must include 'months' or 'Year'";
            }
        }

        if (!formData.course_fees) {
            newErrors.course_fees = "Course fees is required";
        } else {
            const fees = Number(formData.course_fees);
            if (isNaN(fees) || fees < 0) {
                newErrors.course_fees = "Course fees must be a positive number";
            }
        }

        // Course type validation
        if (!formData.course_type) {
            newErrors.course_type = "Course type is required";
        }

        // Course sub type validation
        if (!formData.course_sub_type) {
            newErrors.course_sub_type = "Course sub type is required";
        }

        // Syllabus validation
        if (!formData.syallabus.trim()) {
            newErrors.syallabus = "Syllabus is required";
        } else if (!formData.syallabus.includes(',')) {
            newErrors.syallabus = "Enter multiple topics separated by commas";
        }

        // Image validation
        if (!formData.image_url) {
            newErrors.image_url = "Course image is required";
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            Swal.fire({
                icon: "error",
                title: "Validation Error",
                text: "Please fill in all required fields correctly",
            });
            return;
        }

        setLoading(true);
        console.log(formData);

        try {
            const url = APIData.api + 'courses/';
            const response = await axios.post(url, formData, { headers: APIData.headers });
            Swal.fire({
                icon: "success",
                title: "Course added successfully!",
                showConfirmButton: false,
                timer: 1500
            });

            navigate("/courses-details");

        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Submission Error",
                text: error.response?.data?.message || "Unable to submit the course details"
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={0} justifyContent="center" alignItems="center" style={{ minHeight: "100vh" }}>
                <Backdrop open={loading} style={{ zIndex: 9999, color: '#fff' }}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Grid item xs={12} sm={8}>
                    <Card sx={{ height: 'auto' }}>
                        <SoftBox p={3} mb={1} display="flex" alignItems="center">
                            <Link to="/courses-details">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium" style={{ flex: 1, textAlign: 'center' }}>
                                Add Course
                            </SoftTypography>
                        </SoftBox>

                        <SoftBox pt={1} pb={3} px={3}>
                            <SoftBox component="form" onSubmit={handleSubmit}>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Course Type</SoftTypography>
                                    <CustomSelect
                                        options={courseTypeOptions}
                                        placeholder="Select Course Type"
                                        name="course_type"
                                        onChange={handleSelectChange}
                                        value={courseTypeOptions.find(option => option.value === formData.course_type)}
                                        error={!!errors.course_type}
                                    />
                                    {getErrorHelperText('course_type')}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">
                                        Course Name *
                                        <Tooltip title="Students below 12th class, Enter in given format = Grade-01 to Grade-12">
                                            <IconButton edge="end" size="small">
                                                <HelpCenterRounded />
                                            </IconButton>
                                        </Tooltip>
                                    </SoftTypography>
                                    <SoftInput
                                        placeholder="Enter Course Name"
                                        name="courseName"
                                        value={formData.courseName}
                                        onChange={handleChange}
                                        error={!!errors.courseName}
                                    />
                                    {getErrorHelperText('courseName')}
                                </SoftBox>

                                {formData.course_type === 'ACADEMICS' && (
                                    <SoftBox mb={2}>
                                        <SoftTypography variant="h6">
                                            Grade Subjects
                                            <Tooltip title="Add subjects for this grade">
                                                <IconButton edge="start" size="small">
                                                    <HelpCenterRounded />
                                                </IconButton>
                                            </Tooltip>
                                        </SoftTypography>
                                        {subjects.map((subject, index) => (
                                            <SoftBox key={index} mb={1} display="flex" alignItems="center" gap={1}>
                                                <SoftInput
                                                    fullWidth
                                                    placeholder={`Enter subject ${index + 1}`}
                                                    value={subject}
                                                    onChange={(e) => handleSubjectChange(index, e.target.value)}
                                                    error={!!errors.gradeSubjects}
                                                />
                                                <IconButton
                                                    onClick={() => removeSubjectField(index)}
                                                    disabled={subjects.length === 1}
                                                >
                                                    <Remove />
                                                </IconButton>
                                                {index === subjects.length - 1 && (
                                                    <IconButton onClick={addSubjectField}>
                                                        <Add />
                                                    </IconButton>
                                                )}
                                            </SoftBox>
                                        ))}
                                        {getErrorHelperText('gradeSubjects')}
                                    </SoftBox>
                                )}

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">
                                        Course Description
                                        <Tooltip title="Course Name or description about Course">
                                            <IconButton edge="end" size="small">
                                                <HelpCenterRounded />
                                            </IconButton>
                                        </Tooltip>
                                    </SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="course_description"
                                        placeholder={fetchingDescription ? "Generating description..." : "Enter Course Description"}
                                        value={formData.course_description}
                                        onChange={handleChange}
                                        error={!!errors.course_description}
                                        disabled={fetchingDescription}
                                        multiline
                                        rows={4}
                                    />
                                    {fetchingDescription && (
                                        <SoftTypography variant="caption" color="text">
                                            Generating AI description based on the course name...
                                        </SoftTypography>
                                    )}
                                    {getErrorHelperText('course_description')}
                                    <SoftTypography variant="caption" color="text">
                                        You can edit this description if needed
                                    </SoftTypography>
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">
                                        Course Duration
                                        <Tooltip title="Duration of Courses">
                                            <IconButton edge="start" size="small">
                                                <HelpCenterRounded />
                                            </IconButton>
                                        </Tooltip>
                                    </SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="course_duration"
                                        placeholder="Example: 3 months or 1 Year"
                                        value={formData.course_duration}
                                        onChange={handleChange}
                                        error={!!errors.course_duration}
                                    />
                                    {getErrorHelperText('course_duration')}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Course Fees</SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="course_fees"
                                        placeholder="Enter Course Fees"
                                        value={formData.course_fees}
                                        onChange={handleChange}
                                        type="number"
                                        error={!!errors.course_fees}
                                    />
                                    {getErrorHelperText('course_fees')}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">Course Sub Type</SoftTypography>
                                    <CustomSelect
                                        options={courseSubTypeOptions}
                                        placeholder="Select Course Sub Type"
                                        name="course_sub_type"
                                        onChange={handleSelectChange}
                                        value={courseSubTypeOptions.find(option => option.value === formData.course_sub_type)}
                                        error={!!errors.course_sub_type}
                                    />
                                    {getErrorHelperText('course_sub_type')}
                                </SoftBox>

                                <SoftBox mb={2}>
                                    <SoftTypography variant="h6">
                                        Syllabus
                                        <Tooltip title="Add syllabus Topics with comma separated">
                                            <IconButton edge="start" size="small">
                                                <HelpCenterRounded />
                                            </IconButton>
                                        </Tooltip>
                                    </SoftTypography>
                                    <SoftInput
                                        fullWidth
                                        name="syallabus"
                                        placeholder="Enter Syllabus Topics (comma separated)"
                                        value={formData.syallabus}
                                        onChange={handleChange}
                                        multiline
                                        rows={4}
                                        error={!!errors.syallabus}
                                    />
                                    {getErrorHelperText('syallabus')}
                                </SoftBox>

                                <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
                                    <input
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        id="upload-image"
                                        type="file"
                                        onChange={handleFileChange}
                                    />
                                    <label htmlFor="upload-image">
                                        <SoftButton variant="contained" component="span">
                                            Upload Image
                                        </SoftButton>
                                    </label>
                                    {selectedImage && (
                                        <SoftBox mt={2} display="flex" justifyContent="center" alignItems="center">
                                            <img
                                                src={selectedImage}
                                                alt="Selected Preview"
                                                style={{ width: '200px', height: 'auto', objectFit: 'contain' }}
                                            />
                                        </SoftBox>
                                    )}
                                    {getErrorHelperText('image_url')}
                                </SoftBox>

                                <SoftBox mt={4} mb={1}>
                                    <SoftButton type="submit" variant="gradient" color="info" fullWidth>
                                        Submit
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
};

export default AddCourses;