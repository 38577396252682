import React, { useState, useEffect } from 'react';
import { Card, Grid, FormControl } from "@mui/material";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import * as AiIcons from "react-icons/ai";
import axios from 'axios';
import { APIData } from "authentication/APIData";

function EscalationStudentQuery() {
    const [loading, setLoading] = useState(false);
    const [reason, setReason] = useState("");
    const [userdata, setUserData] = useState(null);

    useEffect(() => {
        const userData_Local = localStorage.getItem("sessiondetails");
        if (userData_Local) {
            try {
                const parsedUserData = JSON.parse(userData_Local);
                setUserData(parsedUserData);
            } catch (error) {
                console.error("Error parsing session details:", error);
            }
        }
    }, []);

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        try {
            // Validate required fields
            if (!reason.trim()) {
                throw new Error("Reason is required");
            }

            if (!userdata?.email) {
                throw new Error("User email not found");
            }

            // Construct the API endpoint
            const endpoint = `${APIData.api}student/updates/sendReminderEmail`;

            // Make the API call using axios
            const response = await axios.get(endpoint, {
                headers: APIData.headers,
                params: {
                    studentEmail: userdata.email,
                    message: reason
                }
            });

            if (response.status === 200) {
                Swal.fire({
                    icon: "success",
                    title: "Success!",
                    text: "Query escalated successfully",
                    timer: 2000,
                    showConfirmButton: false
                });

                // Reset form
                setReason("");
            } else {
                throw new Error('Failed to send reminder email');
            }

        } catch (error) {
            console.error("Detailed Error:", error.response ? error.response.data : error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response?.data?.message || error.message || "Failed to escalate query"
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Grid container spacing={3} justifyContent="center" alignItems="center" mt={3}>
                <Grid item xs={12} sm={12} md={10} lg={8}>
                    <Card>
                        <SoftBox p={3} display="flex" alignItems="center" justifyContent="space-between">
                            <Link to="/student-chat">
                                <AiIcons.AiOutlineCloseCircle />
                            </Link>
                            <SoftTypography variant="h5" fontWeight="medium">
                                Escalate Student Query
                            </SoftTypography>
                            <div style={{ width: "24px" }}></div>
                        </SoftBox>

                        <SoftBox px={3} mb={3}>
                            <Card variant="outlined">
                                <SoftBox p={2}>
                                    <SoftTypography variant="h6" color="info" mb={1}>
                                        What is Escalation?
                                    </SoftTypography>
                                    <SoftTypography variant="body2" mb={2}>
                                        Escalation is the process of raising a query or issue to a higher level of authority when:
                                    </SoftTypography>
                                    <SoftTypography component="div" variant="body2" ml={2}>
                                        • The current solution needs additional expertise or authority<br/>
                                        • The issue requires immediate attention from senior staff<br/>
                                        • The query is complex and needs specialized knowledge<br/>
                                        • Standard procedures haven&apos;t resolved the issue satisfactorily
                                    </SoftTypography>
                                </SoftBox>
                            </Card>
                        </SoftBox>

                        <SoftBox component="form" role="form" onSubmit={handleSubmit} px={3} pb={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <SoftBox>
                                        <SoftTypography variant="h6">Reason for Escalation</SoftTypography>
                                        <FormControl fullWidth>
                                            <SoftInput
                                                placeholder="Enter your reason for escalation"
                                                value={reason}
                                                onChange={(e) => setReason(e.target.value)}
                                                multiline
                                                rows={4}
                                            />
                                        </FormControl>
                                    </SoftBox>
                                </Grid>

                                <Grid item xs={12} display="flex" justifyContent="center">
                                    <SoftButton
                                        type="submit"
                                        variant="gradient"
                                        color="info"
                                        disabled={loading}
                                    >
                                        {loading ? "Submitting..." : "Submit Escalation"}
                                    </SoftButton>
                                </Grid>
                            </Grid>
                        </SoftBox>
                    </Card>
                </Grid>
            </Grid>
        </DashboardLayout>
    );
}

export default EscalationStudentQuery;