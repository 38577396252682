import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Dialog, DialogContent, DialogActions, FormControlLabel, Switch } from '@mui/material';
import { CloudUpload, Preview } from '@mui/icons-material';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import { org, APIData } from 'authentication/APIData';

const styles = {
    container: {
        maxWidth: '800px',
        margin: '0 auto',
        border: '1px solid #e0e0e0',
        borderRadius: '15px',
        backgroundColor: 'white',
    },
    imagePreview: {
        width: '100%',
        height: '200px',
        objectFit: 'cover',
        borderRadius: '10px',
    },
};

const CreateBlog = () => {
    const capitalname = JSON.parse(localStorage.getItem("sessiondetails"));
    const username = capitalname?.user || '';

    const [blogData, setBlogData] = useState({
        title: '',
        content: '',
        featuredImage: '',
        published: false,
        org: org,
        author: username,
    });

    const [previewImage, setPreviewImage] = useState('');
    const [selectedFile, setSelectedFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showPreview, setShowPreview] = useState(false);

    useEffect(() => {
        if (username) {
            setBlogData(prev => ({ ...prev, author: username }));
        }
    }, [username]);

    const handleImageSelection = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setPreviewImage(URL.createObjectURL(file));
        }
    };

    const showSuccessAlert = (message) => {
        Swal.fire({
            title: 'Success!',
            text: message,
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#2196f3'
        });
    };

    const showErrorAlert = (message) => {
        Swal.fire({
            title: 'Error',
            text: message,
            icon: 'error',
            confirmButtonText: 'OK',
            confirmButtonColor: '#f44335'
        });
    };

    const uploadImage = async (postId) => {
        if (!selectedFile) return;

        try {
            const formData = new FormData();
            formData.append('file', selectedFile);

            const response = await axios.post(
                `${APIData.api}blog/details/id/${postId}/image`,
                formData,
                {
                    headers: {
                        ...APIData.headers,
                        'Content-Type': 'multipart/form-data'
                    }
                }
            );

            if (response.data) {
                showSuccessAlert('Blog post and image uploaded successfully!');
            }
        } catch (error) {
            showErrorAlert('Error uploading image, but blog post was created');
            console.error('Image upload error:', error);
        }
    };

    const handleSubmit = async () => {
        if (!blogData.title.trim() || !blogData.content.trim()) {
            showErrorAlert('Please fill in all required fields');
            return;
        }

        setIsLoading(true);
        try {
            const requestBody = {
                ...blogData,
                publishedDate: new Date().toISOString(),
                createdBy: username,
                updatedBy: username,
            };

            const response = await axios.post(
                `${APIData.api}blog/details`,
                requestBody,
                { headers: APIData.headers }
            );

            if (response.data) {
                const postId = response.data.id;

                if (selectedFile) {
                    await uploadImage(postId);
                } else {
                    showSuccessAlert('Blog post published successfully!');
                }

                setBlogData({
                    ...blogData,
                    title: '',
                    content: '',
                    featuredImage: '',
                    published: false,
                });
                setPreviewImage('');
                setSelectedFile(null);
            }
        } catch (error) {
            showErrorAlert('Error publishing blog post');
            console.error('Submission error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <div style={styles.container}>
                    <SoftBox p={3}>
                        <SoftBox mb={3}>
                            <SoftTypography variant="h6">Create New Blog Post</SoftTypography>
                        </SoftBox>

                        <SoftBox mb={2}>
                            <SoftTypography variant="h6">Title</SoftTypography>
                            <SoftInput
                                placeholder="Enter blog title"
                                value={blogData.title}
                                onChange={(e) => setBlogData({ ...blogData, title: e.target.value })}
                                fullWidth
                            />
                        </SoftBox>

                        <SoftBox mb={2}>
                            <input
                                accept="image/*"
                                id="featured-image-upload"
                                type="file"
                                hidden
                                onChange={handleImageSelection}
                            />
                            <label htmlFor="featured-image-upload">
                                <SoftButton
                                    variant="gradient"
                                    color="info"
                                    component="span"
                                    startIcon={<CloudUpload />}
                                >
                                    Upload Featured Image
                                </SoftButton>
                            </label>
                        </SoftBox>

                        {previewImage && (
                            <SoftBox mb={2}>
                                <img
                                    src={previewImage}
                                    alt="Preview"
                                    style={styles.imagePreview}
                                />
                            </SoftBox>
                        )}

                        <SoftBox mb={2}>
                            <SoftTypography variant="h6">Content</SoftTypography>
                            <SoftInput
                                multiline
                                rows={10}
                                placeholder="Enter blog content"
                                value={blogData.content}
                                onChange={(e) => setBlogData({ ...blogData, content: e.target.value })}
                                fullWidth
                            />
                        </SoftBox>

                        <SoftBox display="flex" justifyContent="space-between" alignItems="center">
                            <FormControlLabel
                                control={
                                    <Switch
                                        checked={blogData.published}
                                        onChange={(e) => setBlogData({ ...blogData, published: e.target.checked })}
                                    />
                                }
                                label="Publish immediately"
                            />

                            <SoftBox>
                                <SoftButton
                                    variant="gradient"
                                    color="secondary"
                                    startIcon={<Preview />}
                                    onClick={() => setShowPreview(true)}
                                    sx={{ mr: 2 }}
                                >
                                    Preview
                                </SoftButton>
                                <SoftButton
                                    variant="gradient"
                                    color="info"
                                    onClick={handleSubmit}
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Publishing...' : 'Publish Blog'}
                                </SoftButton>
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>
                </div>

                <Dialog
                    open={showPreview}
                    onClose={() => setShowPreview(false)}
                    maxWidth="md"
                    fullWidth
                >
                    <DialogContent>
                        <SoftBox p={3}>
                            <SoftBox mb={2}>
                                <SoftTypography variant="h4" fontWeight="medium">
                                    {blogData.title}
                                </SoftTypography>
                            </SoftBox>

                            {previewImage && (
                                <SoftBox mb={2}>
                                    <img
                                        src={previewImage}
                                        alt="Featured"
                                        style={styles.imagePreview}
                                    />
                                </SoftBox>
                            )}

                            <SoftBox>
                                <SoftTypography sx={{ whiteSpace: 'pre-wrap' }}>
                                    {blogData.content}
                                </SoftTypography>
                            </SoftBox>
                        </SoftBox>
                    </DialogContent>
                    <DialogActions>
                        <SoftButton
                            variant="gradient"
                            color="secondary"
                            onClick={() => setShowPreview(false)}
                        >
                            Close
                        </SoftButton>
                    </DialogActions>
                </Dialog>
            </SoftBox>
        </DashboardLayout>
    );
};

export default CreateBlog;