// import React, { useState, useEffect, useRef } from 'react';
// import { 
//   Card, 
//   CardContent, 
//   CardMedia, 
//   Typography, 
//   IconButton, 
//   Box, 
//   Container,
//   Grid
// } from '@mui/material';
// import { ChevronLeft, ChevronRight } from '@mui/icons-material';
// import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
// import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
// import DefaultBlogCard from 'examples/Cards/BlogCards/DefaultBlogCard';
// import { org } from 'authentication/APIData';
// import { APIData } from 'authentication/APIData';
// import axios from 'axios';
// import PageLayout from 'examples/LayoutContainers/PageLayout';
// import Header from './Mheader';

// const styles = {
//   scrollContainer: {
//     position: 'relative',
//     marginBottom: '50px',
//   },
//   scrollContent: {
//     display: 'flex',
//     overflowX: 'hidden',
//     scrollBehavior: 'smooth',
//     padding: '0 40px',
//     gap: '16px',
//   },
//   scrollCard: {
//     flexShrink: 0,
//     width: '250px',
//     height: '250px',
//     cursor: 'pointer',
//     transition: 'box-shadow 0.3s ease',
//     '&:hover': {
//       boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
//     },
//   },
//   scrollButton: {
//     position: 'absolute',
//     top: '50%',
//     transform: 'translateY(-50%)',
//     zIndex: 1,
//     backgroundColor: '#fff',
//     boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
//     '&:hover': {
//       backgroundColor: '#f5f5f5',
//     },
//   },
//   leftButton: {
//     left: 0,
//   },
//   rightButton: {
//     right: 0,
//   },
//   cardMedia: {
//     height: '150px',
//   },
//   cardContent: {
//     padding: '8px',
//     '&:last-child': {
//       paddingBottom: '8px',
//     },
//   },
//   mainContent: {
//     padding: '24px 0',
//   },
//   title: {
//     marginBottom: '32px',
//     fontWeight: 'bold',
//   },
// };

// const Blog = () => {
//     const [blogPosts, setBlogPosts] = useState([]);
//     const [selectedPost, setSelectedPost] = useState(null);
//     const scrollContainerRef = useRef(null);

//     useEffect(() => {
//         const fetchBlogPosts = async () => {
//             try {
//                 const response = await fetch(`${APIData.api}blog/details/org/${org}`, {
//                     method: 'GET',
//                     headers: APIData.headers
//                 });
//                 const data = await response.json();
//                 setBlogPosts(data);
//             } catch (error) {
//                 console.error('Error fetching blog posts:', error);
//             }
//         };

//         fetchBlogPosts();
//     }, []);

//     const scroll = (direction) => {
//         if (scrollContainerRef.current) {
//             const scrollAmount = 280;
//             const container = scrollContainerRef.current;
//             const newPosition = container.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);
//             container.scrollTo({
//                 left: newPosition,
//                 behavior: 'smooth'
//             });
//         }
//     };

//     const handleCardClick = (post) => {
//         setSelectedPost(post);
//     };

//     return (
//            <PageLayout>
//              <Header/>
//             <Container maxWidth="lg">
//             <Typography variant="h2" sx={styles.title} style={{textAlign:"center", marginTop:"20px"}}>
//                     All Blog Posts
//                 </Typography>
//                 <Box sx={styles.scrollContainer}>
//                     <IconButton 
//                         onClick={() => scroll('left')}
//                         sx={{ ...styles.scrollButton, ...styles.leftButton }}
//                     >
//                         <ChevronLeft />
//                     </IconButton>

//                     <Box 
//                         ref={scrollContainerRef}
//                         sx={styles.scrollContent}
//                     >
//                         {blogPosts.map((post) => (
//                             <Card 
//                                 key={post.id}
//                                 sx={styles.scrollCard}
//                                 onClick={() => handleCardClick(post)}
//                             >
//                                 <CardMedia
//                                     component="img"
//                                     image={post.featuredImage}
//                                     alt={post.title}
//                                     sx={styles.cardMedia}
//                                 />
//                                 <CardContent sx={styles.cardContent}>
//                                     <Typography noWrap variant="subtitle2">
//                                         {post.title}
//                                     </Typography>
//                                     <Typography noWrap variant="caption" color="text.secondary">
//                                         {post.author}
//                                     </Typography>
//                                 </CardContent>
//                             </Card>
//                         ))}
//                     </Box>

//                     <IconButton 
//                         onClick={() => scroll('right')}
//                         sx={{ ...styles.scrollButton, ...styles.rightButton }}
//                     >
//                         <ChevronRight />
//                     </IconButton>
//                 </Box>

//                 {selectedPost && (
//                     <Box mb={6}>
//                         <DefaultBlogCard
//                             image={selectedPost.featuredImage}
//                             category={{
//                                 color: 'primary',
//                                 label: selectedPost.org,
//                             }}
//                             title={selectedPost.title}
//                             description={selectedPost.content}
//                             author={{
//                                 name: selectedPost.author,
//                                 date: selectedPost.publishedDate,
//                             }}
//                             action={{
//                                 type: 'internal',
//                             }}
//                         />
//                     </Box>
//                 )}

//                 <Typography variant="h4" sx={styles.title}>
//                     Latest Blog Posts
//                 </Typography>
                
//                 <Grid container spacing={3}>
//                     {blogPosts.map((post) => (
//                         <Grid item xs={12} md={6} lg={4} key={post.id}>
//                             <DefaultBlogCard
//                                 image={post.featuredImage}
//                                 category={{
//                                     color: 'primary',
//                                     label: post.org,
//                                 }}
//                                 title={post.title}
//                                 description={post.content}
//                                 author={{
//                                     name: post.author,
//                                     date: post.publishedDate,
//                                 }}
//                                 action={{
//                                     type: 'internal',
//                                 }}
//                             />
//                         </Grid>
//                     ))}
//                 </Grid>
//             </Container>
//     </PageLayout>
//     );
// };

// export default Blog;



import React, { useState, useEffect, useRef } from 'react';
import { 
  Card, 
  CardContent, 
  CardMedia, 
  Typography, 
  IconButton, 
  Box, 
  Container,
  Grid
} from '@mui/material';
import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import PageLayout from 'examples/LayoutContainers/PageLayout';
import DefaultBlogCard from 'examples/Cards/BlogCards/DefaultBlogCard';
import { org } from 'authentication/APIData';
import { APIData } from 'authentication/APIData';
import Header from './Mheader';
import Footers from './Footers';

const styles = {
  scrollContainer: {
    position: 'relative',
    marginBottom: '50px',
  },
  scrollContent: {
    display: 'flex',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    padding: '0 40px',
    gap: '16px',
  },
  scrollCard: {
    flexShrink: 0,
    width: '250px',
    height: '250px',
    cursor: 'pointer',
    transition: 'box-shadow 0.3s ease',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    },
  },
  scrollButton: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,
    backgroundColor: '#fff',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    '&:hover': {
      backgroundColor: '#f5f5f5',
    },
  },
  leftButton: {
    left: 0,
  },
  rightButton: {
    right: 0,
  },
  cardMedia: {
    height: '150px',
  },
  cardContent: {
    padding: '8px',
    '&:last-child': {
      paddingBottom: '8px',
    },
  },
  mainContent: {
    padding: '24px 0',
  },
  title: {
    marginBottom: '32px',
    fontWeight: 'bold',
  },
};

const Blog = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [selectedPost, setSelectedPost] = useState(null);
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        const fetchBlogPosts = async () => {
            try {
                const response = await fetch(`${APIData.api}blog/details/org/${org}`, {
                    method: 'GET',
                    headers: APIData.headers
                });
                const data = await response.json();

                // Filter only published blogs and sort by publishedDate (latest first)
                const filteredAndSortedBlogs = data
                    .filter((post) => post.published) // Only include published posts
                    .sort((a, b) => new Date(...b.publishedDate) - new Date(...a.publishedDate));

                setBlogPosts(filteredAndSortedBlogs);
            } catch (error) {
                console.error('Error fetching blog posts:', error);
            }
        };

        fetchBlogPosts();
    }, []);

    const scroll = (direction) => {
        if (scrollContainerRef.current) {
            const scrollAmount = 280;
            const container = scrollContainerRef.current;
            const newPosition = container.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);
            container.scrollTo({
                left: newPosition,
                behavior: 'smooth'
            });
        }
    };

    const handleCardClick = (post) => {
        setSelectedPost(post);
    };

    return (
           <PageLayout>
             <Header/>
            <Container maxWidth="lg" sx={{ marginBottom: "70px" }}>
            <Box display="flex" alignItems="center" gap={2} sx={{ marginTop: "80px" }}>
    <IconButton onClick={() => window.location.href = "/"} sx={{ color: "black" }}>
        <ChevronLeft />
    </IconButton>
            <Typography variant="h2" sx={styles.title} style={{textAlign:"center", marginTop:"80px"}}>
                    All Blog Posts
                </Typography>
                </Box>
                                <Box sx={styles.scrollContainer}>
                    <IconButton 
                        onClick={() => scroll('left')}
                        sx={{ ...styles.scrollButton, ...styles.leftButton }}
                    >
                        <ChevronLeft />
                    </IconButton>

                    <Box 
                        ref={scrollContainerRef}
                        sx={styles.scrollContent}
                    >
                        {blogPosts.map((post) => (
                            <Card 
                                key={post.id}
                                sx={styles.scrollCard}
                                onClick={() => handleCardClick(post)}
                            >
                                <CardMedia
                                    component="img"
                                    image={post.featuredImage}
                                    alt={post.title}
                                    sx={styles.cardMedia}
                                />
                                <CardContent sx={styles.cardContent}>
                                    <Typography noWrap variant="subtitle2">
                                        {post.title}
                                    </Typography>
                                    <Typography noWrap variant="caption" color="text.secondary">
                                        {post.author}
                                    </Typography>
                                </CardContent>
                            </Card>
                        ))}
                    </Box>

                    <IconButton 
                        onClick={() => scroll('right')}
                        sx={{ ...styles.scrollButton, ...styles.rightButton }}
                    >
                        <ChevronRight />
                    </IconButton>
                </Box>

                {selectedPost && (
                    <Box mb={6}>
                        <DefaultBlogCard
                            image={selectedPost.featuredImage}
                            category={{
                                color: 'primary',
                                label: selectedPost.org,
                            }}
                            title={selectedPost.title}
                            description={selectedPost.content}
                            author={{
                                name: selectedPost.author,
                                date: selectedPost.publishedDate,
                            }}
                            action={{
                                type: 'internal',
                            }}
                        />
                    </Box>
                )}

                <Typography variant="h4" sx={styles.title}>
                    Latest Blog Posts
                </Typography>
                
                <Grid container spacing={3}>
                    {blogPosts.map((post) => (
                        <Grid item xs={12} md={6} lg={4} key={post.id}>
                            <DefaultBlogCard
                                image={post.featuredImage}
                                category={{
                                    color: 'primary',
                                    label: post.org,
                                }}
                                title={post.title}
                                description={post.content}
                                author={{
                                    name: post.author,
                                    date: post.publishedDate,
                                }}
                                action={{
                                    type: 'internal',
                                }}
                            />
                        </Grid>
                    ))}
                </Grid>
            </Container>
            <Footers/>
    </PageLayout>

    );
};

export default Blog;