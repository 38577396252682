import React from "react";

const buttonStyle = {
  display: "block",
  width: "100%",
  padding: "12px",
  borderRadius: "6px",
  border: "1px solid #007BFF",
  backgroundColor: "white", // White background
  color: "#007BFF", // Blue text color
  textAlign: "center",
  fontSize: "16px",
  fontWeight: "bold",
  cursor: "pointer",
  transition: "background-color 0.3s ease, color 0.3s ease", // Added transition for smooth hover effect
};

const cardStyle = {
  backgroundColor: "white",
  padding: "20px",
  borderRadius: "8px",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
};

const containerStyle = {
  maxWidth: "900px",
  margin: "0 auto",
  padding: "40px 20px",
  textAlign: "center",
};

const gridStyle = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
  gap: "20px",
};

const plans = [
  {
    name: "Basic",
    price: "$9",
    features: ["5 Projects", "Up to 10 Users", "Basic Analytics", "Email Support"],
  },
  {
    name: "Pro",
    price: "$29",
    features: [
      "Unlimited Projects",
      "Unlimited Users",
      "Advanced Analytics",
      "Priority Support",
      "Custom Integrations",
    ],
  },
  {
    name: "Enterprise",
    price: "Custom",
    features: [
      "All Pro Features",
      "Dedicated Account Manager",
      "Custom Reporting",
      "On-Premise Deployment Option",
      "SLA",
    ],
  },
];

export default function Pricing() {
  return (
    <section style={containerStyle} id="pricing">
      <h2 style={{ fontSize: "28px", fontWeight: "bold", marginBottom: "24px", color: "#333" }}>
        Choose Your Plan
      </h2>
      <div style={gridStyle}>
        {plans.map((plan, index) => (
          <div key={index} style={cardStyle}>
            <h3 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "16px" }}>{plan.name}</h3>
            <p style={{ fontSize: "32px", fontWeight: "bold", marginBottom: "20px" }}>
              {plan.price}
              <span style={{ fontSize: "14px", fontWeight: "normal", color: "#666" }}>/month</span>
            </p>
            <ul style={{ listStyleType: "none", padding: "0", marginBottom: "20px", flexGrow: 1 }}>
              {plan.features.map((feature, featureIndex) => (
                <li key={featureIndex} style={{ marginBottom: "8px", color: "#444" }}>{feature}</li>
              ))}
            </ul>
            <button
              style={buttonStyle}
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = "#007BFF"; // Make the button blue
                e.currentTarget.style.color = "white"; // White text on hover
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = "white"; // White background
                e.currentTarget.style.color = "#007BFF"; // Blue text on hover out
              }}
            >
              {index === 2 ? "Contact Sales" : "Get Started"}
            </button>
          </div>
        ))}
      </div>
    </section>
  );
}


// import React from "react";

// const buttonStyle = {
//   display: "block",
//   width: "100%",
//   padding: "12px",
//   borderRadius: "6px",
//   border: "1px solid #007BFF",
//   backgroundColor: "white", // White background
//   color: "#007BFF", // Blue text color
//   textAlign: "center",
//   fontSize: "16px",
//   fontWeight: "bold",
//   cursor: "pointer",
//   transition: "background-color 0.3s ease, color 0.3s ease, transform 0.3s ease", // Added transition for color change
// };

// const outlineButtonStyle = {
//   ...buttonStyle,
// };

// const cardStyle = {
//   backgroundColor: "white",
//   padding: "20px",
//   borderRadius: "8px",
//   boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
//   textAlign: "center",
//   display: "flex",
//   flexDirection: "column",
//   width: "200px", // Decreased the width of the cards
//   flexGrow: 1, // Allow cards to grow equally in height
//   height: "120%", // Ensures the cards stretch to the same height
//   transition: "transform 0.1s ease", // Removed hover effect
// };

// const containerStyle = {
//   maxWidth: "100%", // Allow full width for the container
//   margin: "0 auto",
//   padding: "40px 20px",
//   textAlign: "center",
// };

// const gridStyle = {
//   display: "flex", // Flexbox to display cards in a row
//   gap: "16px", // Added gap between cards
//   justifyContent: "center", // Center align the cards horizontally
//   alignItems: "stretch", // Stretch the cards to the same height
//   flexWrap: "wrap", // Allow cards to wrap to the next line if necessary
// };

// const plans = [
//   {
//     name: "Basic",
//     price: "$9",
//     features: ["5 Projects", "Up to 10 Users", "Basic Analytics", "Email Support"],
//   },
//   {
//     name: "Pro",
//     price: "$29",
//     features: [
//       "Unlimited Projects",
//       "Unlimited Users",
//       "Advanced Analytics",
//       "Priority Support",
//       "Custom Integrations",
//     ],
//   },
//   {
//     name: "Enterprise",
//     price: "Custom",
//     features: [
//       "All Pro Features",
//       "Dedicated Account Manager",
//       "Custom Reporting",
//       "On-Premise Deployment Option",
//       "SLA",
//     ],
//   },
// ];

// export default function Pricing() {
//   return (
//     <section style={containerStyle} id="pricing">
//       <h2 style={{ fontSize: "28px", fontWeight: "bold", marginBottom: "24px", color: "#333" }}>
//         Choose Your Plan
//       </h2>
//       <div style={gridStyle}>
//         {plans.map((plan, index) => (
//           <div key={index} style={cardStyle}>
//             <h3 style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "16px" }}>{plan.name}</h3>
//             <p style={{ fontSize: "32px", fontWeight: "bold", marginBottom: "20px" }}>
//               {plan.price}
//               <span style={{ fontSize: "14px", fontWeight: "normal", color: "#666" }}>/month</span>
//             </p>
//             <ul style={{ listStyleType: "none", padding: "0", marginBottom: "20px", flexGrow: 1 }}>
//               {plan.features.map((feature, featureIndex) => (
//                 <li key={featureIndex} style={{ marginBottom: "8px", color: "#444" }}>{feature}</li>
//               ))}
//             </ul>
//             <button
//               style={index === 1 ? buttonStyle : outlineButtonStyle}
//               onMouseEnter={(e) => {
//                 e.currentTarget.style.backgroundColor = "#007BFF"; // Make the button blue
//                 e.currentTarget.style.color = "white"; // White text on hover
//                 e.currentTarget.style.transform = "scale(1.05)"; // Slight scale-up on hover
//               }}
//               onMouseLeave={(e) => {
//                 e.currentTarget.style.backgroundColor = "white"; // Make the button white
//                 e.currentTarget.style.color = "#007BFF"; // Blue text on hover leave
//                 e.currentTarget.style.transform = "scale(1)"; // Reset the scale on hover leave
//               }}
//             >
//               {index === 2 ? "Contact Sales" : "Get Started"}
//             </button>
//           </div>
//         ))}
//       </div>
//     </section>
//   );
// }
