import React, { useState, useEffect } from "react";
import {
    Card,
    CardContent,
    CircularProgress,
    IconButton,
    Tooltip
} from "@mui/material";
import {
    Close,
    Send,
    AttachFile as AttachmentIcon,
    ArrowBackIos
} from "@mui/icons-material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { APIData, org } from "authentication/APIData";
import Swal from 'sweetalert2';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

function NewConversation() {
    const [userdata, setUserData] = useState(null);
    const [student, setStudent] = useState(null);
    const [mentor, setMentor] = useState(null);
    const [replyMessage, setReplyMessage] = useState('');
    const [selectedImages, setSelectedImages] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const userData_Local = localStorage.getItem("sessiondetails");
        if (userData_Local) {
            try {
                const parsedUserData = JSON.parse(userData_Local);
                setUserData(parsedUserData);
            } catch (error) {
                console.error("Error parsing session details:", error);
            }
        }
    }, []);

    useEffect(() => {
        if (userdata?.email) {
            fetchStudentData();
        }
    }, [userdata]);

    useEffect(() => {
        const fetchMentor = async () => {
            if (student?.mentor) {
                try {
                    const response = await fetch(
                        `${APIData.api}employee/detail?emailId=${student.mentor}`,
                        { headers: APIData.headers }
                    );
                    const data = await response.json();
                    setMentor(data);
                } catch (error) {
                    console.error("Error fetching mentor details:", error);
                }
            }
        };
        fetchMentor();
    }, [student?.mentor]);

    const fetchStudentData = async () => {
        try {
            const response = await axios.get(
                `${APIData.api}studentadmissiondetails/email?email=${userdata.email}`,
                { headers: APIData.headers }
            );

            if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                setStudent(response.data[0]);
            }
        } catch (error) {
            console.error("Error fetching student details:", error);
        }
    };

    const handleImageSelect = (e) => {
        const files = Array.from(e.target.files);

        const validImageFiles = files.filter(file => {
            if (file.size > 2 * 1024 * 1024) {
                Swal.fire({
                    icon: "error",
                    title: "Image Too Large",
                    text: `${file.name} exceeds 2MB limit. Please choose a smaller image.`
                });
                return false;
            }
            return true;
        });

        const finalImageFiles = validImageFiles.slice(0, 5 - selectedImages.length);

        if (finalImageFiles.length > 0) {
            setSelectedImages(prev => [...prev, ...finalImageFiles]);
        }
    };

    const removeImage = (indexToRemove) => {
        setSelectedImages(prev => prev.filter((_, index) => index !== indexToRemove));
    };

    const handleSendMessage = async () => {
        if (!mentor || (!replyMessage.trim() && selectedImages.length === 0)) return;

        setIsUploading(true);

        try {

            const now = new Date();
            const formattedDateTime = [
                now.getFullYear(),
                now.getMonth() + 1,
                now.getDate(),
                now.getHours(),
                now.getMinutes(),
                now.getSeconds()
            ];
            const conversationPayload = {
                id: 0,
                studentName: student.name,
                studentId: userdata.username,
                studentEmail: student.emailId,
                mentorId: student.mentor,
                mentorName: mentor.name,
                queries: replyMessage.trim() ? [{
                    updatedBy: userdata.username,
                    updatedDateTime: formattedDateTime,
                    comments: replyMessage
                }] : [],
                studentImages: [],
                mentorImages: [],
                grade: student.studentClass,
                month: new Date().getMonth() + 1,
                year: new Date().getFullYear(),
                org: org,
                owner: "STUDENT",
                createdBy: userdata.username,
                createdDateTime: formattedDateTime,
                viewStatus: "UNREAD"
            };

            // Create conversation first
            const conversationResponse = await axios.post(
                `${APIData.api}student/updates`,
                conversationPayload,
                { headers: APIData.headers }
            );

            const createdConversationId = conversationResponse.data.id;

            // Upload images sequentially
            if (selectedImages.length > 0) {
                for (const imageFile of selectedImages) {
                    const formData = new FormData();
                    formData.append('id', createdConversationId);
                    formData.append('studentFile', imageFile);
                    formData.append('mentorFile', '');

                    await axios.post(
                        `${APIData.api}student/updates/${createdConversationId}/upload-images`,
                        formData,
                        {
                            headers: {
                                ...APIData.headers,
                                'Content-Type': 'multipart/form-data'
                            }
                        }
                    );
                }
            }

            Swal.fire({
                icon: "success",
                title: "Conversation Started",
                text: "Your message and/or images have been sent successfully.",
                showConfirmButton: false,
                timer: 3000
            });

            navigate("/student-chat");

        } catch (error) {
            console.error("Error sending message:", error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to start conversation. Please try again.",
            });
        } finally {
            setIsUploading(false);
        }
    };

    const handleGoBack = () => {
        navigate("/student-chat");
    };

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <CardContent>
                    <SoftBox mb={3}>
                        <Tooltip title="Back to Student chat" arrow>
                            <IconButton onClick={handleGoBack}>
                                <ArrowBackIos />
                            </IconButton>
                        </Tooltip>
                        <SoftTypography variant="h6" ml="4">Start New Conversation</SoftTypography>
                    </SoftBox>

                    {mentor && (
                        <SoftBox display="flex" flexDirection="column" gap={3}>
                            <SoftBox>
                                <SoftTypography variant="subtitle2" mb={1}>Mentor</SoftTypography>
                                <SoftTypography variant="body2">
                                    {mentor.firstName} {mentor.lastName}
                                </SoftTypography>
                            </SoftBox>

                            <SoftBox>
                                <SoftTypography variant="subtitle2" mb={1}>Message</SoftTypography>
                                <SoftInput
                                    multiline
                                    rows={4}
                                    placeholder="Type your message..."
                                    value={replyMessage}
                                    onChange={(e) => setReplyMessage(e.target.value)}
                                    fullWidth
                                />

                                <SoftBox mt={2} display="flex" alignItems="center" gap={2}>
                                    <SoftButton
                                        variant="text"
                                        color="info"
                                        component="label"
                                        startIcon={<AttachmentIcon />}
                                    >
                                        Attach Images
                                        <input
                                            type="file"
                                            hidden
                                            multiple
                                            accept="image/*"
                                            onChange={handleImageSelect}
                                            disabled={selectedImages.length >= 5}
                                        />
                                    </SoftButton>
                                </SoftBox>

                                {selectedImages.length > 0 && (
                                    <SoftBox mt={2} display="flex" gap={1}>
                                        {selectedImages.map((image, index) => (
                                            <SoftBox
                                                key={index}
                                                sx={{
                                                    position: 'relative',
                                                    width: 50,
                                                    height: 50,
                                                    borderRadius: 1,
                                                    overflow: 'hidden'
                                                }}
                                            >
                                                <img
                                                    src={URL.createObjectURL(image)}
                                                    alt={`Selected ${index}`}
                                                    style={{
                                                        width: '100%',
                                                        height: '100%',
                                                        objectFit: 'cover'
                                                    }}
                                                />
                                                <SoftButton
                                                    variant="contained"
                                                    color="error"
                                                    size="small"
                                                    onClick={() => removeImage(index)}
                                                    sx={{
                                                        position: 'absolute',
                                                        top: -5,
                                                        right: -5,
                                                        minWidth: 20,
                                                        width: 20,
                                                        height: 20,
                                                        p: 0,
                                                        borderRadius: '50%'
                                                    }}
                                                >
                                                    <Close fontSize="small" />
                                                </SoftButton>
                                            </SoftBox>
                                        ))}
                                    </SoftBox>
                                )}

                                <SoftBox mt={2} display="flex" justifyContent="flex-end" gap={2}>
                                    <SoftButton
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => navigate("/student-updates")}
                                    >
                                        Cancel
                                    </SoftButton>
                                    <SoftButton
                                        variant="contained"
                                        color="info"
                                        onClick={handleSendMessage}
                                        disabled={(!replyMessage.trim() && selectedImages.length === 0) || isUploading}
                                        startIcon={isUploading ? <CircularProgress size={20} /> : <Send />}
                                    >
                                        Send
                                    </SoftButton>
                                </SoftBox>
                            </SoftBox>
                        </SoftBox>
                    )}
                </CardContent>
            </Card>
        </DashboardLayout>
    );
}

export default NewConversation;