import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import Grid from "@mui/material/Grid";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Divider,
  Card,
  CardContent
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SchoolIcon from '@mui/icons-material/School';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EventNoteIcon from '@mui/icons-material/EventNote';
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import Footer from "examples/Footer";
import Header from "layouts/profile/components/Header";
import { APIData } from "authentication/APIData";

const InformationDisplay = ({ data }) => {
  return (
    <div style={{
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '16px',
      padding: '16px'
    }}>
      {Object.entries(data).map(([key, value]) => (
        <div key={key} style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '8px',
          borderBottom: '1px solid #e0e0e0',
          width: "100%"
        }}>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              fontWeight: 600,
              marginRight: '16px',
              minWidth: '120px'
            }}
          >
            {key}
          </Typography>
          <Typography
            variant="body2"
            style={{
              flex: 1,
              textAlign: 'right'
            }}
          >
            {value || 'N/A'}
          </Typography>
        </div>
      ))}
    </div>
  );
};

const CompactInfoCard = ({ title, icon, onExpand }) => {
  return (
    <Card
      sx={{
        height: '160px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        // transition: 'transform 0.3s ease',
        // '&:hover': {
        //   transform: 'scale(1.05)'
        // }
      }}
      onClick={onExpand}
    >
      <CardContent sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
      }}>
        {icon}
        <Typography
          variant="h6"
          sx={{
            marginTop: '12px',
            color: '#344767',
            fontWeight: 600
          }}
        >
          {title}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: '#7b809a',
            marginTop: '8px'
          }}
        >
          Click to view details
        </Typography>
      </CardContent>
    </Card>
  );
};

const DetailDialog = ({ open, onClose, title, children }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      PaperProps={{
        sx: {
          borderRadius: '12px'
        }
      }}
    >
      <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Typography variant="h6">{title}</Typography>
        <IconButton
          aria-label="close"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {children}
      </DialogContent>
    </Dialog>
  );
};

function StudentProfile() {
  const navigate = useNavigate();
  const [studentData, setStudentData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // State for managing dialogs
  const [openDialogs, setOpenDialogs] = useState({
    personalInfo: false,
    academicInfo: false,
    familyInfo: false,
    academicRecords: false
  });

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const userData = JSON.parse(localStorage.getItem("sessiondetails"));
        if (!userData || !userData.email) {
          throw new Error("No user email found in session");
        }

        const url = `${APIData.api}studentadmissiondetails/email?email=${encodeURIComponent(userData.email)}`;
        const response = await fetch(url, { headers: APIData.headers });
        
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        
        const data = await response.json();
        setStudentData(data[0]);
      } catch (error) {
        console.error("Error fetching student details:", error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchStudentData();
  }, []);

  // Function to toggle dialogs
  const toggleDialog = (dialogName) => {
    setOpenDialogs(prev => ({
      ...prev,
      [dialogName]: !prev[dialogName]
    }));
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  if (!studentData) {
    return <Typography>No student data available</Typography>;
  }

  return (
    <DashboardLayout>
      <Header />
      <SoftBox mt={5} mb={3}>
        <Grid container spacing={3}>
          {/* Personal Information Card */}
          <Grid item xs={12} md={6}>
            <CompactInfoCard
              title="Personal Information"
              icon={<AccountCircleIcon color="info" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('personalInfo')}
            />
            <DetailDialog
              open={openDialogs.personalInfo}
              onClose={() => toggleDialog('personalInfo')}
              title="Personal Information"
            >
              <InformationDisplay
                data={{
                  'Full Name': studentData.name,
                  'Date of Birth': studentData.dateOfBirth,
                  'Gender': studentData.gender,
                  'Blood Group': studentData.bloodGroup,
                  'Mobile Number': studentData.mobileNo,
                  'Email': studentData.emailId,
                  'Aadhar Number': studentData.adharNo
                }}
              />
            </DetailDialog>
          </Grid>

          {/* Academic Information Card */}
          <Grid item xs={12} md={6}>
            <CompactInfoCard
              title="Academic Information"
              icon={<SchoolIcon color="info" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('academicInfo')}
            />
            <DetailDialog
              open={openDialogs.academicInfo}
              onClose={() => toggleDialog('academicInfo')}
              title="Academic Information"
            >
              <InformationDisplay
                data={{
                  'Class': studentData.studentClass,
                  'Syllabus Type': studentData.syllabusType,
                  'Academic Year': studentData.academicYear,
                  'School/College': studentData.schoolOrCollegeName,
                  'Syllabus Subjects': studentData.syllabus_subjects,
                  'Mentor': studentData.mentor
                }}
              />
            </DetailDialog>
          </Grid>

          {/* Family Information Card */}
          <Grid item xs={12} md={6}>
            <CompactInfoCard
              title="Family Information"
              icon={<AssignmentIcon color="info" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('familyInfo')}
            />
            <DetailDialog
              open={openDialogs.familyInfo}
              onClose={() => toggleDialog('familyInfo')}
              title="Family Information"
            >
              <InformationDisplay
                data={{
                  'Father Name': studentData.fatherName,
                  'Mother Name': studentData.motherName,
                  'Address': studentData.address,
                  'City': studentData.city,
                  'State': studentData.state,
                  'Pincode': studentData.pincode
                }}
              />
            </DetailDialog>
          </Grid>

          {/* Academic Records Card */}
          <Grid item xs={12} md={6}>
            <CompactInfoCard
              title="Academic Records"
              icon={<EventNoteIcon color="info" sx={{ fontSize: 50 }} />}
              onExpand={() => toggleDialog('academicRecords')}
            />
            <DetailDialog
              open={openDialogs.academicRecords}
              onClose={() => toggleDialog('academicRecords')}
              title="Academic Records"
            >
              <InformationDisplay
                data={{
                  'Core Minimum Marks': studentData.coreminMarks,
                  'Core Maximum Marks': studentData.coremaxMarks,
                  'Core Marks Obtained': studentData.coremarksObtained,
                  'Language Minimum Marks': studentData.languageminMarks,
                  'Language Maximum Marks': studentData.languagemaxMarks,
                  'Language Marks Obtained': studentData.languagemarksObtained
                }}
              />
            </DetailDialog>
          </Grid>
        </Grid>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

// PropTypes definitions
InformationDisplay.propTypes = {
  data: PropTypes.object.isRequired
};

CompactInfoCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  onExpand: PropTypes.func.isRequired
};

DetailDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default StudentProfile;