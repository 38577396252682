// import React from "react";
// import PropTypes from "prop-types";
// import { Star, BarChart, Users, Shield } from "lucide-react";

// const products = [
//   {
//     id: 1,
//     name: "Financial Management Suite",
//     description: "Comprehensive financial management solution for businesses of all sizes.",
//     price: "$199/month",
//     rating: 4.8,
//     category: "Finance",
//     features: ["General Ledger", "Accounts Payable/Receivable", "Budgeting and Forecasting", "Financial Reporting"],
//     icon: BarChart,
//   },
//   {
//     id: 2,
//     name: "Human Resources Platform",
//     description: "All-in-one HR solution to manage your workforce efficiently.",
//     price: "$149/month",
//     rating: 4.6,
//     category: "HR",
//     features: ["Employee Management", "Payroll Processing", "Time and Attendance", "Recruitment and Onboarding"],
//     icon: Users,
//   },
//   {
//     id: 3,
//     name: "Security and Compliance Tool",
//     description: "Ensure your business stays secure and compliant with industry regulations.",
//     price: "$249/month",
//     rating: 4.9,
//     category: "Security",
//     features: ["Data Encryption", "Access Control", "Compliance Reporting", "Security Audits"],
//     icon: Shield,
//   },
// ];

// const ProductCard = ({ product }) => (
//   <div style={styles.card}>
//     <div style={styles.header}>
//       <h2 style={styles.title}>{product.name}</h2>
//       <product.icon size={24} color="purple" />
//     </div>
//     <p style={styles.description}>{product.description}</p>
//     <div style={styles.rating}>
//       <Star size={20} color="gold" style={styles.starIcon} />
//       <span style={styles.bold}>{product.rating}</span>
//     </div>
//     <ul style={styles.featuresList}>
//       {product.features.map((feature, index) => (
//         <li key={index} style={styles.featureItem}>{feature}</li>
//       ))}
//     </ul>
//     <div style={styles.footer}>
//       <span style={styles.price}>{product.price}</span>
//     </div>
//   </div>
// );

// ProductCard.propTypes = {
//   product: PropTypes.shape({
//     name: PropTypes.string.isRequired,
//     description: PropTypes.string.isRequired,
//     price: PropTypes.string.isRequired,
//     rating: PropTypes.number.isRequired,
//     category: PropTypes.string.isRequired,
//     features: PropTypes.arrayOf(PropTypes.string).isRequired,
//     icon: PropTypes.elementType.isRequired,
//   }).isRequired,
// };

// const ProductPage = () => {
//   const [activeTab, setActiveTab] = React.useState("all");

//   return (
//     <section style={styles.section}>
//       <div style={styles.container}>
//         <h1 style={styles.heading}>Our Products</h1>
//         <div style={styles.tabs}>
//           {["All Products", "Finance", "HR", "Security"].map((category, index) => (
//             <button
//               key={index}
//               style={{
//                 ...styles.tabButton,
//                 backgroundColor: activeTab === category ? "purple" : "white",
//                 color: activeTab === category ? "white" : "black",
//               }}
//               onClick={() => setActiveTab(category)}
//             >
//               {category}
//             </button>
//           ))}
//         </div>
//         <div style={styles.grid}>
//           {products
//             .filter((product) => activeTab === "All Products" || product.category === activeTab)
//             .map((product) => (
//               <ProductCard key={product.id} product={product} />
//             ))}
//         </div>
//       </div>
//     </section>
//   );
// };

// // Inline CSS styles
// const styles = {
//   section: {
//     padding: "60px 0",
//     background: "linear-gradient(to bottom right, #f3e5f5, #e3f2fd)",
//   },
//   container: {
//     maxWidth: "1200px",
//     margin: "0 auto",
//     padding: "0 20px",
//   },
//   heading: {
//     fontSize: "32px",
//     fontWeight: "bold",
//     textAlign: "center",
//     background: "linear-gradient(to right, purple, blue)",
//     WebkitBackgroundClip: "text",
//     color: "transparent",
//     marginBottom: "20px",
//   },
//   tabs: {
//     display: "flex",
//     justifyContent: "center",
//     marginBottom: "20px",
//     gap: "10px",
//   },
//   tabButton: {
//     padding: "10px 15px",
//     border: "1px solid purple",
//     borderRadius: "5px",
//     cursor: "pointer",
//     fontWeight: "bold",
//   },
//   grid: {
//     display: "grid",
//     gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr))",
//     gap: "20px",
//   },
//   card: {
//     border: "1px solid #ddd",
//     borderRadius: "10px",
//     padding: "20px",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//     height: "100%",
//     backgroundColor: "white",
//   },
//   header: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//   },
//   title: {
//     fontSize: "20px",
//     fontWeight: "bold",
//   },
//   description: {
//     color: "#555",
//     marginBottom: "10px",
//   },
//   rating: {
//     display: "flex",
//     alignItems: "center",
//     marginBottom: "10px",
//   },
//   starIcon: {
//     marginRight: "5px",
//   },
//   bold: {
//     fontWeight: "bold",
//   },
//   featuresList: {
//     listStyle: "none",
//     padding: 0,
//     margin: 0,
//   },
//   featureItem: {
//     color: "#555",
//     fontSize: "14px",
//     marginBottom: "5px",
//   },
//   footer: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//     marginTop: "15px",
//   },
//   price: {
//     fontSize: "18px",
//     fontWeight: "bold",
//     color: "purple",
//   },
// };

// export default ProductPage;

// import React, { useState, useEffect, useRef } from "react";
// import PropTypes from "prop-types";
// import { Star, BarChart, Users, Shield } from "lucide-react";
// import { APIData } from "authentication/APIData";
// import { org } from "authentication/APIData";

// const ProductCard = ({ product }) => (
//   <div style={styles.card}>
//     <div style={styles.header}>
//       <h2 style={styles.title}>{product.org_name}</h2>
//       <product.icon size={24} color="purple" />
//     </div>
//     <p style={styles.description}>{product.customerAddress || "No address available"}</p>
//     <div style={styles.rating}>
//       <Star size={20} color="gold" style={styles.starIcon} />
//       <span style={styles.bold}>{product.salesType}</span>
//     </div>
//     <ul style={styles.featuresList}>
//       <li style={styles.featureItem}>Email: {product.customerEmail}</li>
//       <li style={styles.featureItem}>Organization: {product.org_name}</li>
//     </ul>
//   </div>
// );

// ProductCard.propTypes = {
//   product: PropTypes.shape({
//     customerName: PropTypes.string,
//     customerAddress: PropTypes.string,
//     customerEmail: PropTypes.string,
//     org_name: PropTypes.string,
//     salesType: PropTypes.string,
//     icon: PropTypes.elementType
//   }).isRequired,
// };

// const ProductPage = () => {
//   const [salesData, setSalesData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const scrollContainerRef = useRef(null);

//   useEffect(() => {
//     const fetchSalesData = async () => {
//       try {
//         const response = await fetch(
//           `${APIData.api}customer-sales/salestype?salesType=OPPURTUNITY&org=${org}`,
//           { headers: APIData.headers }
//         );
//         const data = await response.json();
//         const icons = [BarChart, Users, Shield];
//         const processedData = (Array.isArray(data) ? data : [data]).map(item => ({
//           ...item,
//           icon: icons[Math.floor(Math.random() * icons.length)]
//         }));
//         setSalesData(processedData);
//       } catch (error) {
//         console.error('Error fetching sales data:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchSalesData();
//   }, []);

//   const handleScroll = (direction) => {
//     if (scrollContainerRef.current) {
//       const scrollAmount = 300;
//       const newScrollPosition = scrollContainerRef.current.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);
//       scrollContainerRef.current.scrollTo({
//         left: newScrollPosition,
//         behavior: 'smooth'
//       });
//     }
//   };

//   if (loading) {
//     return (
//       <section style={styles.section}>
//         <div style={styles.container}>
//           <h1 style={styles.heading}>Loading...</h1>
//         </div>
//       </section>
//     );
//   }

//   return (
//     <section style={styles.section}>
//       <div style={styles.container}>
//         <h1 style={styles.heading}>Our Clients</h1>
//         <div style={styles.scrollControls}>
//           <button 
//             onClick={() => handleScroll('left')}
//             style={styles.scrollButton}
//           >
//             ←
//           </button>
//           <div style={styles.scrollContainer} ref={scrollContainerRef}>
//             {salesData.map((product, index) => (
//               <div key={index} style={styles.cardWrapper}>
//                 <ProductCard product={product} />
//               </div>
//             ))}
//           </div>
//           <button 
//             onClick={() => handleScroll('right')}
//             style={styles.scrollButton}
//           >
//             →
//           </button>
//         </div>
//       </div>
//     </section>
//   );
// };

// const styles = {
//   section: {
//     padding: "60px 0",
//     background: "linear-gradient(to bottom right, #f3e5f5, #e3f2fd)",
//   },
//   container: {
//     maxWidth: "1200px",
//     margin: "0 auto",
//     padding: "0 20px",
//   },
//   heading: {
//     fontSize: "32px",
//     fontWeight: "bold",
//     textAlign: "center",
//     background: "linear-gradient(to right, purple, blue)",
//     WebkitBackgroundClip: "text",
//     color: "transparent",
//     marginBottom: "20px",
//   },
//   scrollControls: {
//     display: "flex",
//     alignItems: "center",
//     gap: "10px",
//     position: "relative",
//   },
//   scrollContainer: {
//     display: "flex",
//     overflowX: "hidden",
//     scrollBehavior: "smooth",
//     gap: "20px",
//     padding: "20px 0",
//     maxWidth: "100%",
//   },
//   scrollButton: {
//     padding: "10px 15px",
//     backgroundColor: "purple",
//     color: "white",
//     border: "none",
//     borderRadius: "5px",
//     cursor: "pointer",
//     fontSize: "18px",
//     zIndex: 1,
//   },
//   cardWrapper: {
//     flex: "0 0 300px",
//     maxWidth: "300px",
//   },
//   card: {
//     border: "1px solid #ddd",
//     borderRadius: "10px",
//     padding: "20px",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//     height: "100%",
//     backgroundColor: "white",
//     width: "100%",
//   },
//   header: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//   },
//   title: {
//     fontSize: "20px",
//     fontWeight: "bold",
//   },
//   description: {
//     color: "#555",
//     marginBottom: "10px",
//   },
//   rating: {
//     display: "flex",
//     alignItems: "center",
//     marginBottom: "10px",
//   },
//   starIcon: {
//     marginRight: "5px",
//   },
//   bold: {
//     fontWeight: "bold",
//   },
//   featuresList: {
//     listStyle: "none",
//     padding: 0,
//     margin: 0,
//   },
//   featureItem: {
//     color: "#555",
//     fontSize: "14px",
//     marginBottom: "5px",
//   },
// };

// export default ProductPage;

// import React, { useState, useEffect, useRef } from "react";
// import PropTypes from "prop-types";
// import { Star, BarChart, Users, Shield } from "lucide-react";
// import { APIData } from "authentication/APIData";
// import { org } from "authentication/APIData";

// const ProductCard = ({ product }) => (
//   <div style={styles.card}>
//     <div style={styles.header}>
//       <h2 style={styles.title}>{product.org_name}</h2>
//       <product.icon size={24} color="purple" />
//     </div>
//     <p style={styles.description}>{product.customerAddress || "No address available"}</p>
//     <div style={styles.rating}>
//       <Star size={20} color="gold" style={styles.starIcon} />
//       <span style={styles.bold}>{product.salesType}</span>
//     </div>
//     <ul style={styles.featuresList}>
//       <li style={styles.featureItem}>Email: {product.customerEmail}</li>
//       <li style={styles.featureItem}>Organization: {product.org_name}</li>
//     </ul>
//   </div>
// );

// ProductCard.propTypes = {
//   product: PropTypes.shape({
//     customerName: PropTypes.string,
//     customerAddress: PropTypes.string,
//     customerEmail: PropTypes.string,
//     org_name: PropTypes.string,
//     salesType: PropTypes.string,
//     icon: PropTypes.elementType
//   }).isRequired,
// };

// const ProductPage = () => {
//   const [salesData, setSalesData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [isHovering, setIsHovering] = useState(false);
//   const scrollContainerRef = useRef(null);
//   const scrollIntervalRef = useRef(null);
//   const cardWidth = 320; // card width + gap

//   useEffect(() => {
//     const fetchSalesData = async () => {
//       try {
//         const response = await fetch(
//           `${APIData.api}customer-sales/salestype?salesType=OPPURTUNITY&org=${org}`,
//           { headers: APIData.headers }
//         );
//         const data = await response.json();
//         const icons = [BarChart, Users, Shield];
//         const processedData = (Array.isArray(data) ? data : [data]).map(item => ({
//           ...item,
//           icon: icons[Math.floor(Math.random() * icons.length)]
//         }));
//         setSalesData(processedData);
//       } catch (error) {
//         console.error('Error fetching sales data:', error);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchSalesData();
//   }, []);

//   // Auto-scrolling effect
//   useEffect(() => {
//     if (!loading && scrollContainerRef.current && !isHovering) {
//       scrollIntervalRef.current = setInterval(() => {
//         const container = scrollContainerRef.current;
//         const maxScroll = container.scrollWidth - container.clientWidth;
        
//         if (container.scrollLeft >= maxScroll) {
//           // Reset to start when reaching the end
//           container.scrollLeft = 0;
//         } else {
//           container.scrollLeft += cardWidth;
//         }
//       }, 3000); // Scroll every 3 seconds
//     }

//     return () => {
//       if (scrollIntervalRef.current) {
//         clearInterval(scrollIntervalRef.current);
//       }
//     };
//   }, [loading, isHovering]);

//   const handleMouseEnter = () => {
//     setIsHovering(true);
//   };

//   const handleMouseLeave = () => {
//     setIsHovering(false);
//   };

//   const handleScroll = (direction) => {
//     if (scrollContainerRef.current) {
//       const scrollAmount = cardWidth;
//       const newScrollPosition = scrollContainerRef.current.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);
//       scrollContainerRef.current.scrollTo({
//         left: newScrollPosition,
//         behavior: 'smooth'
//       });
//     }
//   };

//   if (loading) {
//     return (
//       <section style={styles.section}>
//         <div style={styles.container}>
//           <h1 style={styles.heading}>Loading...</h1>
//         </div>
//       </section>
//     );
//   }

//   return (
//     <section style={styles.section}>
//       <div style={styles.container}>
//         <h1 style={styles.heading}>Our Clients</h1>
//         <div style={styles.scrollControls}>
//           <button 
//             onClick={() => handleScroll('left')}
//             style={styles.scrollButton}
//           >
//             ←
//           </button>
//           <div 
//             style={styles.scrollContainer} 
//             ref={scrollContainerRef}
//             onMouseEnter={handleMouseEnter}
//             onMouseLeave={handleMouseLeave}
//           >
//             {salesData.map((product, index) => (
//               <div key={index} style={styles.cardWrapper}>
//                 <ProductCard product={product} />
//               </div>
//             ))}
//           </div>
//           <button 
//             onClick={() => handleScroll('right')}
//             style={styles.scrollButton}
//           >
//             →
//           </button>
//         </div>
//       </div>
//     </section>
//   );
// };

// const styles = {
//   section: {
//     padding: "60px 0",
//     background: "linear-gradient(to bottom right, #f3e5f5, #e3f2fd)",
//   },
//   container: {
//     maxWidth: "1200px",
//     margin: "0 auto",
//     padding: "0 20px",
//   },
//   heading: {
//     fontSize: "32px",
//     fontWeight: "bold",
//     textAlign: "center",
//     background: "linear-gradient(to right, purple, blue)",
//     WebkitBackgroundClip: "text",
//     color: "transparent",
//     marginBottom: "20px",
//   },
//   scrollControls: {
//     display: "flex",
//     alignItems: "center",
//     gap: "10px",
//     position: "relative",
//   },
//   scrollContainer: {
//     display: "flex",
//     overflowX: "hidden",
//     scrollBehavior: "smooth",
//     gap: "20px",
//     padding: "20px 0",
//     maxWidth: "100%",
//   },
//   scrollButton: {
//     padding: "10px 15px",
//     backgroundColor: "purple",
//     color: "white",
//     border: "none",
//     borderRadius: "5px",
//     cursor: "pointer",
//     fontSize: "18px",
//     zIndex: 1,
//   },
//   cardWrapper: {
//     flex: "0 0 300px",
//     maxWidth: "300px",
//   },
//   card: {
//     border: "1px solid #ddd",
//     borderRadius: "10px",
//     padding: "20px",
//     display: "flex",
//     flexDirection: "column",
//     justifyContent: "space-between",
//     height: "100%",
//     backgroundColor: "white",
//     width: "100%",
//   },
//   header: {
//     display: "flex",
//     justifyContent: "space-between",
//     alignItems: "center",
//   },
//   title: {
//     fontSize: "20px",
//     fontWeight: "bold",
//   },
//   description: {
//     color: "#555",
//     marginBottom: "10px",
//   },
//   rating: {
//     display: "flex",
//     alignItems: "center",
//     marginBottom: "10px",
//   },
//   starIcon: {
//     marginRight: "5px",
//   },
//   bold: {
//     fontWeight: "bold",
//   },
//   featuresList: {
//     listStyle: "none",
//     padding: 0,
//     margin: 0,
//   },
//   featureItem: {
//     color: "#555",
//     fontSize: "14px",
//     marginBottom: "5px",
//   },
// };

// export default ProductPage;

import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Star, BarChart, Users, Shield } from "lucide-react";
import { APIData } from "authentication/APIData";
import { org } from "authentication/APIData";

const ProductCard = ({ product }) => (
  <div style={styles.card}>
    <div style={styles.header}>
      <h2 style={styles.title}>{product.org_name}</h2>
      <product.icon size={24} color="purple" />
    </div>
    <p style={styles.description}>{product.customerAddress || "No address available"}</p>
    <div style={styles.rating}>
      <Star size={20} color="gold" style={styles.starIcon} />
      <span style={styles.bold}>{product.salesType}</span>
    </div>
    <ul style={styles.featuresList}>
      <li style={styles.featureItem}>Email: {product.customerEmail}</li>
      <li style={styles.featureItem}>Organization: {product.org_name}</li>
    </ul>
  </div>
);

ProductCard.propTypes = {
  product: PropTypes.shape({
    customerName: PropTypes.string,
    customerAddress: PropTypes.string,
    customerEmail: PropTypes.string,
    org_name: PropTypes.string,
    salesType: PropTypes.string,
    icon: PropTypes.elementType
  }).isRequired,
};

const ProductPage = () => {
  const [salesData, setSalesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isHovering, setIsHovering] = useState(false);
  const scrollContainerRef = useRef(null);
  const scrollIntervalRef = useRef(null);
  const cardWidth = 320;

  useEffect(() => {
    const fetchSalesData = async () => {
      try {
        const response = await fetch(
          `${APIData.api}customer-sales/salestype?salesType=OPPURTUNITY&org=${org}`,
          { headers: APIData.headers }
        );
        const data = await response.json();
        const icons = [BarChart, Users, Shield];
        const processedData = (Array.isArray(data) ? data : [data]).map(item => ({
          ...item,
          icon: icons[Math.floor(Math.random() * icons.length)]
        }));
        setSalesData(processedData);
      } catch (error) {
        console.error('Error fetching sales data:', error);
        setSalesData([]);
      } finally {
        setLoading(false);
      }
    };

    fetchSalesData();
  }, []);

  useEffect(() => {
    if (!loading && scrollContainerRef.current && !isHovering && salesData.length > 0) {
      scrollIntervalRef.current = setInterval(() => {
        const container = scrollContainerRef.current;
        const maxScroll = container.scrollWidth - container.clientWidth;
        
        if (container.scrollLeft >= maxScroll) {
          container.scrollLeft = 0;
        } else {
          container.scrollLeft += cardWidth;
        }
      }, 3000);
    }

    return () => {
      if (scrollIntervalRef.current) {
        clearInterval(scrollIntervalRef.current);
      }
    };
  }, [loading, isHovering, salesData.length]);

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  const handleScroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = cardWidth;
      const newScrollPosition = scrollContainerRef.current.scrollLeft + (direction === 'left' ? -scrollAmount : scrollAmount);
      scrollContainerRef.current.scrollTo({
        left: newScrollPosition,
        behavior: 'smooth'
      });
    }
  };

  if (loading) {
    return (
      <section style={styles.section}>
        <div style={styles.container}>
          <h1 style={styles.heading}>Loading...</h1>
        </div>
      </section>
    );
  }

  if (!salesData.length) {
    return (
      <section style={styles.section}>
        <div style={styles.container}>
          <h1 style={styles.heading}>Our Clients</h1>
          <div style={styles.noDataContainer}>
            <p style={styles.noDataText}>No details available</p>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section style={styles.section}>
      <div style={styles.container}>
        <h1 style={styles.heading}>Our Clients</h1>
        <div style={styles.scrollControls}>
          {salesData.length > 1 && (
            <button 
              onClick={() => handleScroll('left')}
              style={styles.scrollButton}
            >
              ←
            </button>
          )}
          <div 
            style={styles.scrollContainer} 
            ref={scrollContainerRef}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {salesData.map((product, index) => (
              <div key={index} style={styles.cardWrapper}>
                <ProductCard product={product} />
              </div>
            ))}
          </div>
          {salesData.length > 1 && (
            <button 
              onClick={() => handleScroll('right')}
              style={styles.scrollButton}
            >
              →
            </button>
          )}
        </div>
      </div>
    </section>
  );
};

const styles = {
  // ... (previous styles remain the same)
  noDataContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "200px",
    backgroundColor: "white",
    borderRadius: "10px",
    border: "1px solid #ddd",
  },
  noDataText: {
    fontSize: "20px",
    color: "#666",
    textAlign: "center",
  },
  section: {
    padding: "60px 0",
    background: "linear-gradient(to bottom right, #f3e5f5, #e3f2fd)",
  },
  container: {
    maxWidth: "1200px",
    margin: "0 auto",
    padding: "0 20px",
  },
  heading: {
    fontSize: "32px",
    fontWeight: "bold",
    textAlign: "center",
    background: "linear-gradient(to right, purple, blue)",
    WebkitBackgroundClip: "text",
    color: "transparent",
    marginBottom: "20px",
  },
  scrollControls: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    position: "relative",
  },
  scrollContainer: {
    display: "flex",
    overflowX: "hidden",
    scrollBehavior: "smooth",
    gap: "20px",
    padding: "20px 0",
    maxWidth: "100%",
  },
  scrollButton: {
    padding: "10px 15px",
    backgroundColor: "purple",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    fontSize: "18px",
    zIndex: 1,
  },
  cardWrapper: {
    flex: "0 0 300px",
    maxWidth: "300px",
  },
  card: {
    border: "1px solid #ddd",
    borderRadius: "10px",
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "100%",
    backgroundColor: "white",
    width: "100%",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  description: {
    color: "#555",
    marginBottom: "10px",
  },
  rating: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  starIcon: {
    marginRight: "5px",
  },
  bold: {
    fontWeight: "bold",
  },
  featuresList: {
    listStyle: "none",
    padding: 0,
    margin: 0,
  },
  featureItem: {
    color: "#555",
    fontSize: "14px",
    marginBottom: "5px",
  },
};

export default ProductPage;