// import SoftButton from "components/SoftButton";
// import { Menu } from "lucide-react";
// import { useState } from "react";
// import { Link } from "react-router-dom";

// export default function Header() {
//   const [isMenuOpen, setIsMenuOpen] = useState(false);

//   return (
//     <header style={{ position: "fixed", top: 0, left: 0, right: 0, zIndex: 50, backgroundColor: "rgba(255, 255, 255, 0.8)", backdropFilter: "blur(10px)" }}>
//       <div style={{ margin: "0 auto", padding: "0 16px", maxWidth: "1200px" }}>
//         <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", height: "80px" }}>
//           <Link to="/" style={{ display: "flex", alignItems: "center", marginRight: "16px" }}>
//             <span style={{ fontSize: "24px", fontWeight: "700", backgroundImage: "linear-gradient(to right, #6a0dad, #1e90ff)", backgroundClip: "text", color: "transparent" }}>
//               dCode
//             </span>
//           </Link>

//           <nav style={{ display: isMenuOpen ? "block" : "none", position: "absolute", top: "80px", left: 0, right: 0, backgroundColor: "white", zIndex: 10, padding: "16px", width: "100%" }}>
//             <ul style={{ display: "flex", flexDirection: "column", alignItems: "center", padding: 0, margin: 0 }}>
//               <li style={{ marginBottom: "8px" }}>
//                 <Link to="/authentication/org-sign-in" style={{ padding: "8px 16px", color: "#4b5563", textDecoration: "none", transition: "color 0.3s", display: "block" }} onMouseEnter={(e) => e.target.style.color = "#6a0dad"} onMouseLeave={(e) => e.target.style.color = "#4b5563"}>
//                   Org Signin
//                 </Link>
//               </li>
//               <li style={{ marginBottom: "8px" }}>
//                 <Link to="#about" style={{ padding: "8px 16px", color: "#4b5563", textDecoration: "none", transition: "color 0.3s", display: "block" }} onMouseEnter={(e) => e.target.style.color = "#6a0dad"} onMouseLeave={(e) => e.target.style.color = "#4b5563"}>
//                   About
//                 </Link>
//               </li>
//               <li style={{ marginBottom: "8px" }}>
//                 <Link to="#pricing" style={{ padding: "8px 16px", color: "#4b5563", textDecoration: "none", transition: "color 0.3s", display: "block" }} onMouseEnter={(e) => e.target.style.color = "#6a0dad"} onMouseLeave={(e) => e.target.style.color = "#4b5563"}>
//                   Pricing
//                 </Link>
//               </li>
//               <li style={{ marginBottom: "8px" }}>
//                 <Link to="#contact" style={{ padding: "8px 16px", color: "#4b5563", textDecoration: "none", transition: "color 0.3s", display: "block" }} onMouseEnter={(e) => e.target.style.color = "#6a0dad"} onMouseLeave={(e) => e.target.style.color = "#4b5563"}>
//                   Contact
//                 </Link>
//               </li>
//               <li style={{ marginLeft: "16px" }}>
//                 <SoftButton style={{ width: "100%", background: "linear-gradient(to right, #6a0dad, #1e90ff)", borderRadius: "8px", padding: "12px 24px", color: "#fff", border: "none", cursor: "pointer", transition: "background 0.3s" }} onMouseEnter={(e) => e.target.style.background = "#6a0dad"} onMouseLeave={(e) => e.target.style.background = "linear-gradient(to right, #6a0dad, #1e90ff)"}>
//                   Get Started
//                 </SoftButton>
//               </li>
//             </ul>
//           </nav>

//           <button onClick={() => setIsMenuOpen(!isMenuOpen)} style={{ display: "block", background: "none", border: "none", cursor: "pointer" }} aria-label="Toggle menu">
//             <Menu style={{ width: "24px", height: "24px", color: "#4b5563" }} />
//           </button>
//         </div>
//       </div>
//     </header>
//   );
// }



// import { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
// import PropTypes from "prop-types";
// import { APIData} from "authentication/APIData";
// import { org } from "authentication/APIData";

// export default function Header() {
//   const [orgDetails, setOrgDetails] = useState({
//     orgName: "",
//     orgAddress: "",
//     logoUrl: "",
//     idCardTemplate: "1",
//   });

//   useEffect(() => {
//     const fetchOrgData = async () => {
//       try {
//         const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`, {
//           headers: APIData.headers,
//         });
//         const orgData = await response.json();
//         setOrgDetails({
//           orgName: orgData.orgShortName,
//           orgAddress: orgData.orgAddress || "",
//           logoUrl: orgData.orgLogo || "",
//           idCardTemplate: orgData.idCardTemplate || "1",
//         });
//         console.log("logo url : ", orgData.orgLogo);

//       } catch (error) {
//         console.error("Error fetching organization data:", error);
//       }
//     };

//     fetchOrgData();
//   }, [org]);

//   return (
//     <header
//       style={{
//         position: "fixed",
//         top: 0,
//         left: 0,
//         right: 0,
//         zIndex: 50,
//         backgroundColor: "rgba(255, 255, 255, 0.8)",
//         backdropFilter: "blur(10px)",
//       }}
//     >
//       <div
//         style={{
//           margin: "0 auto",
//           padding: "0 16px",
//           maxWidth: "1200px",
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "space-between",
//           height: "80px",
//         }}
//       >
//         {/* Logo and Org Name */}
//         <Link
//           to="/"
//           style={{
//             display: "flex",
//             alignItems: "center",
//             marginRight: "16px",
//           }}
//         >
//           {orgDetails.logoUrl && (
//             <img
//               src={orgDetails.logoUrl}
//               alt="Organization Logo"
//               style={{ height: "40px", marginRight: "8px" }}
//             />
//           )}
//           <span
//             style={{
//               fontSize: "24px",
//               fontWeight: "700",
//               backgroundImage: "linear-gradient(to right, #6a0dad, #1e90ff)",
//               backgroundClip: "text",
//               color: "transparent",
//             }}
//           >
//             {orgDetails.orgName}
//           </span>
//         </Link>

//         {/* Navigation Links */}
//         <nav style={{ marginLeft: "auto" }}>
//           <ul
//             style={{
//               display: "flex",
//               alignItems: "center",
//               gap: "24px",
//               listStyle: "none",
//               padding: 0,
//               margin: 0,
//             }}
//           >
//             <li>
//               <Link
//                 to="/authentication/sign-in"
//                 style={{
//                   padding: "8px 16px",
//                   color: "#4b5563",
//                   textDecoration: "none",
//                   transition: "color 0.3s",
//                 }}
//                 onMouseEnter={(e) => (e.target.style.color = "#6a0dad")}
//                 onMouseLeave={(e) => (e.target.style.color = "#4b5563")}
//               >
//                 User Signin
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/authentication/org-sign-in"
//                 style={{
//                   padding: "8px 16px",
//                   color: "#4b5563",
//                   textDecoration: "none",
//                   transition: "color 0.3s",
//                 }}
//                 onMouseEnter={(e) => (e.target.style.color = "#6a0dad")}
//                 onMouseLeave={(e) => (e.target.style.color = "#4b5563")}
//               >
//                 Org Signin
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/authentication/sign-up"
//                 style={{
//                   padding: "8px 16px",
//                   color: "#4b5563",
//                   textDecoration: "none",
//                   transition: "color 0.3s",
//                 }}
//                 onMouseEnter={(e) => (e.target.style.color = "#6a0dad")}
//                 onMouseLeave={(e) => (e.target.style.color = "#4b5563")}
//               >
//                 Sign Up
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/courses"
//                 style={{
//                   padding: "8px 16px",
//                   color: "#4b5563",
//                   textDecoration: "none",
//                   transition: "color 0.3s",
//                 }}
//                 onMouseEnter={(e) => (e.target.style.color = "#6a0dad")}
//                 onMouseLeave={(e) => (e.target.style.color = "#4b5563")}
//               >
//                 Courses
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/userJobs"
//                 style={{
//                   padding: "8px 16px",
//                   color: "#4b5563",
//                   textDecoration: "none",
//                   transition: "color 0.3s",
//                 }}
//                 onMouseEnter={(e) => (e.target.style.color = "#6a0dad")}
//                 onMouseLeave={(e) => (e.target.style.color = "#4b5563")}
//               >
//                 Careers
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/customer-sign-in"
//                 style={{
//                   padding: "8px 16px",
//                   color: "#4b5563",
//                   textDecoration: "none",
//                   transition: "color 0.3s",
//                 }}
//                 onMouseEnter={(e) => (e.target.style.color = "#6a0dad")}
//                 onMouseLeave={(e) => (e.target.style.color = "#4b5563")}
//               >
//                 Support
//               </Link>
//             </li>
//             <li>
//               <Link
//                 to="/asat"
//                 style={{
//                   padding: "8px 16px",
//                   color: "#4b5563",
//                   textDecoration: "none",
//                   transition: "color 0.3s",
//                 }}
//                 onMouseEnter={(e) => (e.target.style.color = "#6a0dad")}
//                 onMouseLeave={(e) => (e.target.style.color = "#4b5563")}
//               >
//                 ASAT
//               </Link>
//             </li>
//           </ul>
//         </nav>
//       </div>
//     </header>
//   );
// }

// Header.propTypes = {
//   org: PropTypes.string.isRequired, // Ensures 'org' is a required string prop
// };


import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { APIData } from "authentication/APIData";
import { org } from "authentication/APIData";
import { Menu, X } from "lucide-react";

export default function Header() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [orgDetails, setOrgDetails] = useState({
    orgName: "",
    orgAddress: "",
    logoUrl: "",
    idCardTemplate: "1",
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > 768) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`, {
          headers: APIData.headers,
        });
        const orgData = await response.json();
        setOrgDetails({
          orgName: orgData.orgShortName,
          orgAddress: orgData.orgAddress || "",
          logoUrl: orgData.orgLogo || "",
          idCardTemplate: orgData.idCardTemplate || "1",
        });
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };

    fetchOrgData();
  }, [org]);

  const navLinks = [
    { to: "/authentication/sign-in", text: "User Signin" },
    { to: "/authentication/org-sign-in", text: "Org Signin" },
    { to: "/authentication/sign-up", text: "Sign Up" },
    { to: "/courses", text: "Courses" },
    { to: "/userJobs", text: "Careers" },
    { to: "/customer-sign-in", text: "Support" },
    { to: "/asat", text: "ASAT" },
  ];

  return (
    <header
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 50,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        backdropFilter: "blur(10px)",
      }}
    >
      <div
        style={{
          margin: "0 auto",
          padding: "0 16px",
          maxWidth: "1200px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "80px",
        }}
      >
        {/* Logo and Org Name */}
        <Link
          to="/"
          style={{
            display: "flex",
            alignItems: "center",
            marginRight: "16px",
          }}
        >
          {orgDetails.logoUrl && (
            <img
              src={orgDetails.logoUrl}
              alt="Organization Logo"
              style={{ height: "40px", marginRight: "8px" }}
            />
          )}
          <span
            style={{
              fontSize: "24px",
              fontWeight: "700",
              backgroundImage: "linear-gradient(to right, #6a0dad, #1e90ff)",
              backgroundClip: "text",
              WebkitBackgroundClip: "text",
              color: "transparent",
            }}
          >
            {orgDetails.orgName}
          </span>
        </Link>

        {/* Menu Toggle Button for Mobile */}
        {windowWidth <= 768 && (
          <button
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            style={{
              background: "none",
              border: "none",
              padding: "8px",
              cursor: "pointer",
              color: "#4b5563",
            }}
          >
            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        )}

        {/* Navigation Links - Show on desktop or when menu is open on mobile */}
        <nav
          style={{
            marginLeft: "auto",
            display: windowWidth > 768 || isMenuOpen ? "block" : "none",
            ...(windowWidth <= 768 && {
              position: "absolute",
              top: "80px",
              left: 0,
              right: 0,
              backgroundColor: "rgba(255, 255, 255, 0.95)",
              padding: "16px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
            }),
          }}
        >
          <ul
            style={{
              display: "flex",
              alignItems: windowWidth > 768 ? "center" : "flex-start",
              flexDirection: windowWidth > 768 ? "row" : "column",
              gap: windowWidth > 768 ? "24px" : "16px",
              listStyle: "none",
              padding: 0,
              margin: 0,
            }}
          >
            {navLinks.map((link) => (
              <li key={link.to}>
                <Link
                  to={link.to}
                  style={{
                    padding: "8px 16px",
                    color: "#4b5563",
                    textDecoration: "none",
                    transition: "color 0.3s",
                    display: "block",
                  }}
                  onMouseEnter={(e) => (e.target.style.color = "#6a0dad")}
                  onMouseLeave={(e) => (e.target.style.color = "#4b5563")}
                  onClick={() => windowWidth <= 768 && setIsMenuOpen(false)}
                >
                  {link.text}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
}

Header.propTypes = {
  org: PropTypes.string.isRequired,
};
