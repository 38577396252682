// import React from "react";
// import { Link } from "react-router-dom";
// import { Facebook, Twitter, Linkedin, Instagram } from "lucide-react";

// export default function Footers() {
//   return (
//     <footer style={{ backgroundColor: "#2d2d2d", color: "#ffffff", padding: "48px 0" }}>
//       <div style={{ maxWidth: "1200px", margin: "0 auto", display: "grid", gridTemplateColumns: "1fr", gap: "32px", padding: "0 16px" }}>
//         <div>
//           <h3 style={{ fontSize: "18px", fontWeight: "600", marginBottom: "16px" }}>StreamLine</h3>
//           <p style={{ fontSize: "14px", color: "#9ca3af" }}>
//             Simplifying project management for teams worldwide.
//           </p>
//         </div>
//         <div>
//           <h4 style={{ fontSize: "18px", fontWeight: "600", marginBottom: "16px" }}>Product</h4>
//           <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
//             <li style={{ marginBottom: "8px" }}>
//               <Link to="#features" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
//                 Features
//               </Link>
//             </li>
//             <li style={{ marginBottom: "8px" }}>
//               <Link to="#pricing" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
//                 Pricing
//               </Link>
//             </li>
//             <li style={{ marginBottom: "8px" }}>
//               <Link to="#" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
//                 Integrations
//               </Link>
//             </li>
//           </ul>
//         </div>
//         <div>
//           <h4 style={{ fontSize: "18px", fontWeight: "600", marginBottom: "16px" }}>Company</h4>
//           <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
//             <li style={{ marginBottom: "8px" }}>
//               <Link to="#" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
//                 About Us
//               </Link>
//             </li>
//             <li style={{ marginBottom: "8px" }}>
//               <Link to="#" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
//                 Careers
//               </Link>
//             </li>
//             <li style={{ marginBottom: "8px" }}>
//               <Link to="#" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
//                 Contact
//               </Link>
//             </li>
//           </ul>
//         </div>
//         <div>
//           <h4 style={{ fontSize: "18px", fontWeight: "600", marginBottom: "16px" }}>Connect</h4>
//           <div style={{ display: "flex", gap: "16px" }}>
//             <Link to="#" style={{ color: "#9ca3af", transition: "color 0.3s", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
//               <Facebook style={{ width: "24px", height: "24px" }} />
//             </Link>
//             <Link to="#" style={{ color: "#9ca3af", transition: "color 0.3s", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
//               <Twitter style={{ width: "24px", height: "24px" }} />
//             </Link>
//             <Link to="#" style={{ color: "#9ca3af", transition: "color 0.3s", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
//               <Linkedin style={{ width: "24px", height: "24px" }} />
//             </Link>
//             <Link to="#" style={{ color: "#9ca3af", transition: "color 0.3s", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
//               <Instagram style={{ width: "24px", height: "24px" }} />
//             </Link>
//           </div>
//         </div>
//       </div>
//       <div style={{ maxWidth: "1200px", margin: "0 auto", marginTop: "32px", paddingTop: "32px", borderTop: "1px solid #374151", textAlign: "center", fontSize: "14px", color: "#9ca3af" }}>
//         <p>&copy; 2023 StreamLine. All rights reserved.</p>
//       </div>
//     </footer>
//   );
// }


import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { Facebook, Twitter, Linkedin, Instagram,Youtube } from "lucide-react";
import { org, APIData } from "authentication/APIData";
import axios from "axios";

export default function Footers() {

  const [socialLinks, setSocialLinks] = useState({});

  useEffect(() => {
    axios
      .get(`${APIData.api}social-media/org/${org}`,{
        headers: APIData.headers,
      }) // Replace {org} with actual org value
      .then((response) => {
        console.log("API Response:", response.data);
        setSocialLinks(response.data);
      })
      .catch((error) => console.error("Error fetching social media links:", error));
  }, []);
  return (
    <footer style={{ backgroundColor: "#2d2d2d", color: "#ffffff", padding: "16px 0" }}> {/* Reduced padding */}
      <div style={{ maxWidth: "1200px", margin: "0 auto", display: "grid", gridTemplateColumns: "1fr", gap: "24px", padding: "0 16px" }}> {/* Reduced gap */}
        <div>
          <h3 style={{ fontSize: "18px", fontWeight: "600", marginBottom: "12px" }}>StreamLine</h3> {/* Reduced margin */}
          <p style={{ fontSize: "20px", color: "#9ca3af" }}> {/* Reduced font size */}
            Simplifying project management for teams worldwide.
          </p>
        </div>

        <div style={{ display: "flex", gap: "80px" }}> {/* Reduced gap */}
          <div>
            <h3 style={{ fontSize: "18px", fontWeight: "600", marginBottom: "12px" }}>Product</h3> {/* Reduced margin */}
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              <li style={{ marginBottom: "6px" }}> {/* Reduced margin */}
                <Link to="#features" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
                  Features
                </Link>
              </li>
              <li style={{ marginBottom: "6px" }}> {/* Reduced margin */}
                <Link to="#pricing" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
                  Pricing
                </Link>
              </li>
              <li style={{ marginBottom: "6px" }}> {/* Reduced margin */}
                <Link to="#" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
                  Integrations
                </Link>
              </li>
              <li style={{ marginBottom: "6px" }}> {/* Reduced margin */}
                <Link to="/blog" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
                  Blog
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 style={{ fontSize: "18px", fontWeight: "600", marginBottom: "12px" }}>Company</h3> {/* Reduced margin */}
            <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
              <li style={{ marginBottom: "6px" }}> {/* Reduced margin */}
                <Link to="/about-us" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
                  About Us
                </Link>
              </li>
              <li style={{ marginBottom: "6px" }}> {/* Reduced margin */}
                <Link to="/contact-us" style={{ fontSize: "14px", color: "#9ca3af", textDecoration: "none" }} onMouseEnter={(e) => e.target.style.color = "#ffffff"} onMouseLeave={(e) => e.target.style.color = "#9ca3af"}>
                  Contact
                </Link>
              </li>
            </ul>
          </div>

          <div>
            <h3 style={{ fontSize: "18px", fontWeight: "600", marginBottom: "12px" }}>Connect</h3> {/* Reduced margin */}
            <div style={{ display: "flex", gap: "12px" }}> {/* Reduced gap */}
            {socialLinks.facebook && (
  <Link to={socialLinks.facebook} target="_blank" rel="noopener noreferrer" style={{ color: "#9ca3af", textDecoration: "none" }}>
    <Facebook style={{ width: "24px", height: "24px" }} />
  </Link>
)}
{socialLinks.twitter && (
  <Link to={socialLinks.twitter} target="_blank" rel="noopener noreferrer" style={{ color: "#9ca3af", textDecoration: "none" }}>
    <Twitter style={{ width: "24px", height: "24px" }} />
  </Link>
)}
{socialLinks.linkedin && (
  <Link to={socialLinks.linkedin} target="_blank" rel="noopener noreferrer" style={{ color: "#9ca3af", textDecoration: "none" }}>
    <Linkedin style={{ width: "24px", height: "24px" }} />
  </Link>
)}
{socialLinks.instagram && (
  <Link to={socialLinks.instagram} target="_blank" rel="noopener noreferrer" style={{ color: "#9ca3af", textDecoration: "none" }}>
    <Instagram style={{ width: "24px", height: "24px" }} />
  </Link>
)}
{socialLinks.youtube && (
  <Link to={socialLinks.youtube} target="_blank" rel="noopener noreferrer" style={{ color: "#9ca3af", textDecoration: "none" }}>
    <Youtube style={{ width: "24px", height: "24px" }} />
  </Link>
)}

            </div>
          </div>
        </div>
      </div>

      <div style={{ maxWidth: "1200px", margin: "0 auto", marginTop: "24px", paddingTop: "24px", borderTop: "1px solid #374151", textAlign: "center", fontSize: "14px", color: "#9ca3af" }}> {/* Reduced margin and padding */}
        <p>&copy; 2023 StreamLine. All rights reserved.</p>
      </div>
    </footer>
  );
}
