

import React, { useState, useEffect } from "react";
import {
    Card,
    CardContent,
    Dialog,
    DialogContent,
    Chip,
    Divider,
    IconButton,
    Tooltip,
    CircularProgress
} from "@mui/material";
import {
    NotificationsNoneOutlined,
    MarkChatUnread,
    CheckCircleOutline,
    MarkunreadOutlined,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    Close,
    ArrowBackIos
} from "@mui/icons-material";
import SoftBox from "components/SoftBox";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import SoftInput from "components/SoftInput";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { APIData, org } from "authentication/APIData";
import Swal from 'sweetalert2';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useNavigate } from "react-router-dom";

const ChatMessage = ({ message, isCurrentUser }) => {
    const formatDateTime = (dateTimeArray) => {
        if (!dateTimeArray) return '';
        const [year, month, day, hour, minute] = dateTimeArray;
        const date = new Date(year, month - 1, day, hour, minute);
        return date.toLocaleString();
    };

    return (
        <SoftBox
            display="flex"
            justifyContent={isCurrentUser ? "flex-end" : "flex-start"}
            mb={2}
        >
            <SoftBox
                sx={{
                    maxWidth: "70%",
                    p: 2,
                    borderRadius: 2,
                    bgcolor: isCurrentUser ? "info.main" : "grey.200",
                    color: isCurrentUser ? "white" : "text.primary",
                    position: "relative",
                    "&::after": {
                        content: '""',
                        position: "absolute",
                        width: 0,
                        height: 0,
                        border: "8px solid transparent",
                        borderTopColor: isCurrentUser ? "info.main" : "grey.200",
                        top: 0,
                        [isCurrentUser ? "right" : "left"]: -8,
                    }
                }}
            >
                <SoftTypography variant="body2">{message?.comments || ''}</SoftTypography>
                <SoftTypography variant="caption" sx={{ opacity: 0.8, display: "block", mt: 1 }}>
                    {formatDateTime(message?.updatedDateTime)}
                </SoftTypography>
            </SoftBox>
        </SoftBox>
    );
};

// PropTypes validation
ChatMessage.propTypes = {
    message: PropTypes.shape({
        comments: PropTypes.string,
        updatedDateTime: PropTypes.arrayOf(PropTypes.number)
    }),
    isCurrentUser: PropTypes.bool
};

function StudentNotifications() {
    const [userdata, setUserData] = useState(null);
    const [student, setStudent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [dataLoading, setDataLoading] = useState(false);
    const [conversationData, setConversationData] = useState([]);
    const [page, setPage] = useState(0);
    const [itemsPerPage] = useState(5);
    const [selectedNotification, setSelectedNotification] = useState(null);
    const [mentorData, setMentor] = useState({ name: 'Loading...' });
    const navigate = useNavigate();

    const today = new Date().toISOString().split('T')[0];
    const [fromDate, setFromDate] = useState(today);
    const [toDate, setToDate] = useState(today);

    useEffect(() => {
        const userData_Local = localStorage.getItem("sessiondetails");
        if (userData_Local) {
            try {
                const parsedUserData = JSON.parse(userData_Local);
                setUserData(parsedUserData);
            } catch (error) {
                console.error("Error parsing session details:", error);
            }
        }
    }, []);

    useEffect(() => {
        if (userdata?.email) {
            fetchStudentData();
        }
    }, [userdata]);

    useEffect(() => {
        if (student && student.mentor) {
            fetchConversationData();
        }
    }, [student, fromDate, toDate]);

    const fetchStudentData = async () => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${APIData.api}studentadmissiondetails/email?email=${userdata.email}`,
                { headers: APIData.headers }
            );

            if (response.data && Array.isArray(response.data) && response.data.length > 0) {
                setStudent(response.data[0]);
            } else {
                Swal.fire({
                    icon: "warning",
                    title: "No Data",
                    text: "No student details found for this email.",
                });
            }
        } catch (error) {
            console.error("Detailed Error:", error.response ? error.response.data : error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: error.response?.data?.message || "Unable to fetch student details",
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchConversationData = async () => {
        if (!student?.mentor) return;
        setDataLoading(true);

        try {
            const response = await fetch(
                `${APIData.api}student/updates/mentor-date-range?mentorId=${student.mentor}&org=${org}&fromDate=${fromDate}&toDate=${toDate}`,
                { headers: APIData.headers }
            );
            const data = await response.json();

            // Filter and sort conversations
            const mentorConversations = data
                .filter(conversation => conversation.owner === "MENTOR")
                .sort((a, b) => {
                    const dateA = new Date(...a.createdDateTime);
                    const dateB = new Date(...b.createdDateTime);
                    return dateB - dateA; // Sort in descending order (latest first)
                });

            setConversationData(mentorConversations);
            setSelectedNotification(null);
            setPage(0); // Reset to first page when new data is loaded

        } catch (error) {
            console.error("Error fetching conversation data:", error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to fetch notifications",
            });
        } finally {
            setDataLoading(false);
        }
    };

    const updateNotificationViewStatus = async (notificationId) => {
        try {
            const response = await fetch(
                `${APIData.api}student/updates/update-view-status/${notificationId}?viewStatus=READ`,
                {
                    method: 'PUT',
                    headers: {
                        ...APIData.headers,
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (!response.ok) {
                throw new Error('Failed to update view status');
            }


        } catch (error) {
            console.error("Error updating notification view status:", error);
            Swal.fire({
                icon: "error",
                title: "Update Failed",
                text: "Could not mark notification as read.",
            });
        }
    };


    const getNotificationIcon = (conversation) => {
        // Logic to determine icon based on conversation type
        return conversation.queries.length > 0
            ? <MarkChatUnread color="info" />
            : <NotificationsNoneOutlined />;
    };

    const formatDateTime = (dateTimeArray) => {
        if (!dateTimeArray) return '';
        const [year, month, day, hour, minute] = dateTimeArray;
        const date = new Date(year, month - 1, day, hour, minute);
        return date.toLocaleString();
    };

    if (loading) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <h3>Loading student details...</h3>
                </div>
            </DashboardLayout>
        );
    }

    const handleDialogClose = () => {
        setSelectedNotification(null);
        fetchConversationData(); // Only fetch data when dialog is closed
    };

    const handleGoBack = () => {
        navigate("/student-chat");
    };

    const paginatedData = conversationData.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const totalPages = Math.ceil(conversationData.length / itemsPerPage);

    if (loading) {
        return (
            <DashboardLayout>
                <DashboardNavbar />
                <SoftBox
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    minHeight="60vh"
                >
                    <CircularProgress />
                    <SoftTypography ml={2}>Loading student details...</SoftTypography>
                </SoftBox>
            </DashboardLayout>
        );
    }

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <Card>
                <CardContent>
                    <SoftBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        mb={3}
                    >
                        <SoftBox display="flex" alignItems="center">
                            <Tooltip title="Back to Student chat" arrow>
                                <IconButton onClick={handleGoBack}>
                                    <ArrowBackIos />
                                </IconButton>
                            </Tooltip>
                            <SoftTypography variant="h5" ml="4">
                                Notifications
                            </SoftTypography>
                        </SoftBox>
                        <Chip
                            icon={<NotificationsNoneOutlined />}
                            label={conversationData.length}
                            color="primary"
                            variant="outlined"
                        />
                    </SoftBox>

                    <SoftBox display="flex" alignItems="center" justifyContent="space-between" mb={3}>
                        <SoftBox display="flex" alignItems="center" gap={3}>
                            <SoftBox display="flex" alignItems="center" gap={2}>
                                <SoftTypography variant="h6">From:</SoftTypography>
                                <SoftInput
                                    type="date"
                                    required
                                    value={fromDate}
                                    onChange={(e) => setFromDate(e.target.value)}
                                    sx={{ width: "150px" }}
                                />
                            </SoftBox>

                            <SoftBox display="flex" alignItems="center" gap={2}>
                                <SoftTypography variant="h6">To:</SoftTypography>
                                <SoftInput
                                    type="date"
                                    required
                                    value={toDate}
                                    onChange={(e) => setToDate(e.target.value)}
                                    sx={{ width: "150px" }}
                                />
                            </SoftBox>
                        </SoftBox>
                    </SoftBox>

                    <SoftBox>
                        {dataLoading ? (
                            <SoftBox
                                display="flex"
                                justifyContent="center"
                                alignItems="center"
                                p={4}
                            >
                                <CircularProgress />
                                <SoftTypography ml={2}>Loading notifications...</SoftTypography>
                            </SoftBox>
                        ) : (
                            <>
                                {paginatedData.map((conversation) => (
                                    <SoftBox
                                        key={conversation.id}
                                        sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            p: 2,
                                            bgcolor: conversation.viewStatus === "UNREAD"
                                                ? 'rgba(0, 255, 0, 0.1)'
                                                : 'rgba(0, 176, 255, 0.1)',
                                            borderRadius: 2,
                                            cursor: 'pointer',
                                            mb: 2,
                                            transition: 'background-color 0.3s',
                                            '&:hover': {
                                                bgcolor: 'rgba(0, 0, 0, 0.05)'
                                            }
                                        }}
                                        onClick={() => {
                                            setSelectedNotification(conversation);
                                            updateNotificationViewStatus(conversation.id);
                                        }}
                                    >
                                        <SoftBox mr={2} position="relative">
                                            {getNotificationIcon(conversation)}
                                            {conversation.viewStatus === "UNREAD" && (
                                                <SoftBox
                                                    sx={{
                                                        position: 'absolute',
                                                        top: '-4px',
                                                        right: '-4px',
                                                        width: '12px',
                                                        height: '12px',
                                                        borderRadius: '50%',
                                                        bgcolor: 'green',
                                                    }}
                                                />
                                            )}
                                        </SoftBox>
                                        <SoftBox flex={1}>
                                            <SoftTypography
                                                variant="body2"
                                                fontWeight={conversation.viewStatus === "UNREAD" ? "bold" : "normal"}
                                            >
                                                Message from {conversation.mentorName}
                                            </SoftTypography>
                                            <SoftTypography variant="caption" color="text.secondary">
                                                {conversation.queries[0]?.comments || 'No message'}
                                            </SoftTypography>
                                        </SoftBox>
                                        <SoftTypography variant="caption" color="text.secondary" ml={2}>
                                            {formatDateTime(conversation.createdDateTime)}
                                        </SoftTypography>
                                    </SoftBox>
                                ))}

                                {/* Pagination */}
                                <SoftBox display="flex" justifyContent="flex-end" p={2}>
                                    <SoftBox display="flex" gap={1} alignItems="center">
                                        <SoftTypography variant="caption" color="secondary">
                                            {conversationData.length === 0
                                                ? "No results"
                                                : `${page * itemsPerPage + 1}-${Math.min((page + 1) * itemsPerPage, conversationData.length)} of ${conversationData.length}`
                                            }
                                        </SoftTypography>
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                            disabled={page === 0}
                                            iconOnly
                                        >
                                            <KeyboardArrowLeft />
                                        </SoftButton>
                                        <SoftButton
                                            variant="text"
                                            color="info"
                                            onClick={() => setPage(prev => Math.min(totalPages - 1, prev + 1))}
                                            disabled={page >= totalPages - 1}
                                            iconOnly
                                        >
                                            <KeyboardArrowRight />
                                        </SoftButton>
                                    </SoftBox>
                                </SoftBox>
                            </>
                        )}
                    </SoftBox>

                    {/* Notification Detail Dialog */}
                    {selectedNotification && (
                        <Dialog
                            open={!!selectedNotification}
                            onClose={handleDialogClose}
                            maxWidth="md"
                            fullWidth
                        >
                            <DialogContent>
                                <SoftBox display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                                    <SoftBox display="flex" alignItems="center" gap={2}>
                                        <SoftAvatar
                                            src={`https://ui-avatars.com/api/?name=${encodeURIComponent(selectedNotification.mentorName)}&background=random`}
                                            alt={selectedNotification.mentorName}
                                            size="sm"
                                        />
                                        <SoftBox>
                                            <SoftTypography variant="h6">
                                                {selectedNotification.mentorName}
                                            </SoftTypography>
                                            <SoftTypography variant="caption" color="text.secondary">
                                                Mentor
                                            </SoftTypography>
                                        </SoftBox>
                                    </SoftBox>
                                    <SoftButton
                                        variant="text"
                                        color="secondary"
                                        onClick={() => setSelectedNotification(null)}
                                        iconOnly
                                    >
                                        <Close />
                                    </SoftButton>
                                </SoftBox>

                                {/* Chat Messages */}
                                <SoftBox
                                    sx={{
                                        height: "300px",
                                        overflowY: "auto",
                                        p: 2,
                                        bgcolor: "grey.100",
                                        borderRadius: 1
                                    }}
                                >
                                    {selectedNotification.queries.map((query, index) => (
                                        <ChatMessage
                                            key={index}
                                            message={query}
                                            isCurrentUser={query.updatedBy !== selectedNotification.mentorId}
                                        />
                                    ))}
                                </SoftBox>
                            </DialogContent>
                        </Dialog>
                    )}
                </CardContent>
            </Card>
        </DashboardLayout>
    );
}

export default StudentNotifications;