import React from "react";
import { Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Hero from "./Hero";
import Features from "./Features";
import ProductPage from "./ProductPage";
import Stats from "./Stats";
import Updates from "./Updates";
import Pricing from "./Pricing";
import Testimonials from "./Testimonials";
import SoftButton from "components/SoftButton";
import SoftBox from "components/SoftBox";
import Footers from "./Footers";
import SoftTypography from "components/SoftTypography";
import PageLayout from "examples/LayoutContainers/PageLayout";

function SetUplandingPage() {
    const navigate = useNavigate();

    const handleBack = () => {
        navigate("/dashboard");
    };

    return (
        <PageLayout>
            <SoftBox
                display="flex"
                alignItems="center"
                position="relative"
                mt={2}
                mb={2}
            >
                <SoftBox position="absolute" left={0} ml={4}>
                    <Tooltip title="Back to Dashboard" arrow>
                        <SoftButton onClick={handleBack}>
                            Back
                        </SoftButton>
                    </Tooltip>
                </SoftBox>

                <SoftBox width="100%" display="flex" justifyContent="center">
                    <SoftTypography variant="h2" fontWeight="regular" color="text">
                        SetUp Landing Page details here
                    </SoftTypography>
                </SoftBox>
            </SoftBox>
            <Hero />
            <Features />
            <ProductPage />
            <Stats />
            <Updates />
            <Pricing />
            <Testimonials />
            <Footers />
        </PageLayout>
    );
}

export default SetUplandingPage;
