import { useEffect, useState } from "react";

const stats = [
  {
    number: 5000,
    label: "Active Users",
    suffix: "+",
  },
  {
    number: 95,
    label: "Customer Satisfaction",
    suffix: "%",
  },
  {
    number: 15,
    label: "Years Experience",
    suffix: "+",
  },
  {
    number: 24,
    label: "Countries Served",
    suffix: "",
  },
];

export default function Stats() {
  const [hasAnimated, setHasAnimated] = useState(false);

  useEffect(() => {
    setHasAnimated(true);
  }, []);

  return (
    <>
      <section style={{ padding: "80px 0", background: "linear-gradient(to bottom right, #f3e8ff, #e0f2fe)" }}>
        <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 20px" }}>
          <div style={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))", gap: "32px" }}>
            {stats.map((stat, index) => (
              <div key={index} style={{ backgroundColor: "#fff", padding: "24px", borderRadius: "8px", boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)", textAlign: "center" }}>
                <div style={{ fontSize: "40px", fontWeight: "bold", background: "linear-gradient(to right, #9333ea, #2563eb)", WebkitBackgroundClip: "text", color: "transparent", marginBottom: "8px" }}>
                  {hasAnimated ? stat.number : 0}{stat.suffix}
                </div>
                <p style={{ color: "#555" }}>{stat.label}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}
