// import { useState, useEffect } from "react";
// import Card from "@mui/material/Card";
// import Dialog from "@mui/material/Dialog";
// import DialogContent from "@mui/material/DialogContent";
// import Grid from "@mui/material/Grid";
// import SoftBox from "components/SoftBox";
// import SoftTypography from "components/SoftTypography";
// import SoftAvatar from "components/SoftAvatar";
// import DashboardNavbar from "examples/Navbars/DashboardNavbar";
// import maleProfileImage from "./User-Profile-PNG-Picture.png";
// import femaleProfileImage from "./-avatar-people-person-profile-user-women-icon--icon-search-engine-23.png";
// import undisclosedProfileImage from "./png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-removebg-preview.png";
// import image from "assets/images/curved-images/2b574b11b0fb509db1531bd8bfcc9f3b.jpg";
// import Swal from "sweetalert2";
// import { org } from "authentication/APIData";
// import { APIData } from "authentication/APIData";
// import IconButton from "@mui/material/IconButton";
// import EditIcon from "@mui/icons-material/Edit";
// import { Tooltip } from "@mui/material";
// import SoftButton from "components/SoftButton";
// import { useNavigate } from "react-router-dom";

// function Header() {
//   const [tabsOrientation, setTabsOrientation] = useState("horizontal");
//   const [profileInfo, setProfileData] = useState({});
//   const [userdata, setUserData] = useState();
//   const [open, setOpen] = useState(false);
//   const [openEditDialog, setOpenEditDialog] = useState(false);
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [imagePreview, setImagePreview] = useState(null);
//   const [isLoading, setIsLoading] = useState(false);
//   const [orgLogo, setOrgLogo] = useState();
//   const [orgShortName, setOrgShortName] = useState("");
//   const navigate = useNavigate();

//   useEffect(() => {
//     const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
//     setUserData(userData_Local);
//   }, []);

//   useEffect(() => {
//     const fetchOrgData = async () => {
//       try {
//         const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`);
//         if (!response.ok) {
//           throw new Error("Failed to fetch organization data");
//         }
//         const data = await response.json();
//         console.log(data);
//         setOrgLogo(data.orgLogo);
//         setOrgShortName(data.orgName);
//       } catch (error) {
//         console.error("Error fetching organization data:", error);
//       }
//     };
//     fetchOrgData()
//   }, [org])

//   useEffect(() => {
//     if (userdata) {
//       const fetchData = async () => {
//         try {
//           let url = `${APIData.api}employee/${userdata.userName}`;
//           const response = await fetch(url, { headers: APIData.headers });
//           const data = await response.json();
//           setProfileData(data);
//         } catch (error) {
//           Swal.fire({
//             title: "Error!",
//             text: `Failed to fetch "${org}" details`,
//             icon: "error",
//             confirmButtonText: "OK",
//           });
//         }
//       };

//       fetchData();
//     }
//   }, [userdata]);

//   const handleClickOpen = () => setOpen(true);
//   const handleClose = () => setOpen(false);

//   const handleClickEditOpen = () => setOpenEditDialog(true);
//   const handleEditClose = () => setOpenEditDialog(false);

//   const handleFileChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       if (file.size > 1024 * 1024) {
//         setOpenEditDialog(false);
//         setOpen(false);
//         setImagePreview(null);
//         setSelectedFile(null);
//         fetchProfileData();
//         Swal.fire({
//           title: "Error!",
//           text: "Image size should be less than 1MB",
//           icon: "error",
//           confirmButtonText: "OK",
//         });
//         return;
//       }

//       setSelectedFile(file);
//       setImagePreview(URL.createObjectURL(file));
//     }
//   };


//   const handleSaveImage = async () => {
//     if (!selectedFile) {
//       Swal.fire({
//         title: "Error!",
//         text: "No file selected",
//         icon: "error",
//         confirmButtonText: "OK",
//       });
//       return;
//     }

//     setIsLoading(true);
//     const formData = new FormData();
//     formData.append("id", profileInfo.employee_id);
//     formData.append("file", selectedFile);

//     try {
//       const response = await fetch(`${APIData.api}employee/${profileInfo.employee_id}/image`, {
//         method: "POST",
//         headers: APIData.headers,
//         body: formData,
//       });
//       const data = await response.json();

//       if (response.ok) {
//         setProfileData({ ...profileInfo, photoUrl: data.photoUrl });
//         setOpenEditDialog(false);
//         setOpen(false);
//         setImagePreview(null);
//         setSelectedFile(null);
//         fetchProfileData();
//         Swal.fire({
//           title: "Success!",
//           text: "Profile image updated successfully",
//           icon: "success",
//           confirmButtonText: "OK",
//         })

//       } else {
//         Swal.fire({
//           title: "Error!",
//           text: data.message || "Failed to update image",
//           icon: "error",
//           confirmButtonText: "OK",
//         });
//       }
//     } catch (error) {
//       Swal.fire({
//         title: "Error!",
//         text: "Something went wrong, please try again",
//         icon: "error",
//         confirmButtonText: "OK",
//       });
//     } finally {
//       setIsLoading(false);
//     }
//   };

//   // Fetch profile data to update the profile image
//   const fetchProfileData = async () => {
//     try {
//       const url = `${APIData.api}employee/${userdata.userName}`;
//       const response = await fetch(url, { headers: APIData.headers });
//       const data = await response.json();
//       setProfileData(data); // Update the profile info with the new image URL
//     } catch (error) {
//       Swal.fire({
//         title: "Error!",
//         text: `Failed to fetch "${org}" details`,
//         icon: "error",
//         confirmButtonText: "OK",
//       });
//     }
//   };

//   const handleChangePasswordClick = () => {
//     navigate("/changePassword");
//   };

//   const handleCancelEdit = () => {
//     setSelectedFile(null);
//     setImagePreview(null);
//     setOpenEditDialog(false);
//   };

//   return (
//     <SoftBox position="relative">
//       <DashboardNavbar absolute light />
//       <SoftBox
//         display="flex"
//         alignItems="center"
//         position="relative"
//         minHeight="18.75rem"
//         borderRadius="xl"
//         sx={{
//           backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
//             `${linearGradient(
//               rgba(gradients.info.main, 0.6),
//               rgba(gradients.info.state, 0.6)
//             )}, url(${image})`,
//           backgroundSize: "cover",
//           backgroundPosition: "50%",
//           overflow: "hidden",
//         }}
//       >
//         <SoftBox
//           sx={{
//             display: 'flex',
//             alignItems: 'center',
//             position: "absolute",
//             top: "40px",
//             left: "40px",
//             zIndex: 1,
//           }}
//         >
//           <SoftBox
//             component="img"
//             src={orgLogo}
//             alt="Logo"
//             sx={{
//               width: "150px",
//               height: "150px",
//               marginRight: "10px",
//             }}
//           />
//           {orgShortName && (
//             <SoftTypography
//               variant="h6"
//               fontWeight="medium"
//               color="dark"
//               sx={{
//                 fontSize: '2rem',
//               }}
//             >
//               {orgShortName}
//             </SoftTypography>
//           )}
//         </SoftBox>
//       </SoftBox>

//       <Card
//         sx={{
//           backdropFilter: `saturate(200%) blur(30px)`,
//           backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
//           boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
//           position: "relative",
//           mt: -8,
//           mx: 3,
//           py: 2,
//           px: 2,
//         }}
//       >
//         <Grid container spacing={3} alignItems="center" justifyContent="space-between">
//           <Grid item>
//             <SoftAvatar
//               src={
//                 profileInfo.photoUrl ||
//                 (profileInfo.gender === "Male"
//                   ? maleProfileImage
//                   : profileInfo.gender === "Female"
//                     ? femaleProfileImage
//                     : undisclosedProfileImage)
//               }
//               alt="profile-image"
//               variant="rounded"
//               size="xl"
//               shadow="sm"
//               sx={{ objectFit: "contain", cursor: "pointer" }} // Add cursor pointer
//               onClick={handleClickOpen} // Open dialog on click
//             />
//           </Grid>
//           <Grid item>
//             <SoftBox height="100%" mt={0.5} lineHeight={1}>
//               <SoftTypography variant="h5" fontWeight="medium">
//                 {profileInfo.name
//                   ? profileInfo.name
//                     .split(" ")
//                     .map(
//                       (word) =>
//                         word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
//                     )
//                     .join(" ")
//                   : "Name"}
//               </SoftTypography>
//               <SoftTypography variant="button" color="text" fontWeight="medium">
//                 {profileInfo.emy_type}
//               </SoftTypography>
//             </SoftBox>
//           </Grid>
//           <Grid item sx={{ ml: "auto" }}> {/* Move the button to the right */}
//             <SoftBox height="100%" mt={0.5} lineHeight={1}>
//               <SoftButton
//                 variant="gradient"
//                 color="info"
//                 onClick={handleChangePasswordClick}
//                 disabled={isLoading}
//               >
//                 Change Password?
//               </SoftButton>
//             </SoftBox>
//           </Grid>
//         </Grid>

//       </Card>

//       {/* Profile Image Dialog */}
//       <Dialog open={open} onClose={handleClose} maxWidth="sm">
//         <DialogContent sx={{ p: 0 }}>
//           <img
//             src={
//               profileInfo.photoUrl ||
//               (profileInfo.gender === "Male"
//                 ? maleProfileImage
//                 : profileInfo.gender === "Female"
//                   ? femaleProfileImage
//                   : undisclosedProfileImage)
//             }
//             alt="Full-size profile"
//             style={{ width: "100%", height: "auto" }}
//           />
//           <Tooltip title="Edit Profile Picture" arrow>
//             <IconButton
//               onClick={handleClickEditOpen}
//               sx={{
//                 position: "absolute",
//                 top: 8,
//                 right: 8,
//                 backgroundColor: "white",
//                 "&:hover": {
//                   backgroundColor: "lightgray",
//                 },
//               }}
//             >
//               <EditIcon />
//             </IconButton>
//           </Tooltip>
//         </DialogContent>
//       </Dialog>

//       <Dialog open={openEditDialog} onClose={handleEditClose} maxWidth="sm">
//         <DialogContent sx={{ p: 3 }}>
//           <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
//             <input
//               accept="image/*"
//               style={{ display: 'none' }}
//               id="upload-image"
//               type="file"
//               onChange={handleFileChange}
//             />
//             <label htmlFor="upload-image">
//               <SoftButton variant="contained" component="span">
//                 Upload Image
//               </SoftButton>
//             </label>
//             {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: "60%", height: "60%" }} />}
//           </SoftBox>
//           <Grid container spacing={2} sx={{ mt: 2 }}>
//             <Grid item>
//               <SoftButton variant="gradient" color="secondary" onClick={handleCancelEdit}>Cancel</SoftButton>
//             </Grid>
//             <Grid item>
//               <SoftButton variant="gradient" color="success" onClick={handleSaveImage} disabled={isLoading}>
//                 {isLoading ? "Saving..." : "Save"}
//               </SoftButton>
//             </Grid>
//           </Grid>
//         </DialogContent>
//       </Dialog>
//     </SoftBox>
//   );
// }

// export default Header;


import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftAvatar from "components/SoftAvatar";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import maleProfileImage from "./User-Profile-PNG-Picture.png";
import femaleProfileImage from "./-avatar-people-person-profile-user-women-icon--icon-search-engine-23.png";
import undisclosedProfileImage from "./png-transparent-profile-logo-computer-icons-user-user-blue-heroes-logo-removebg-preview.png";
import image from "assets/images/curved-images/2b574b11b0fb509db1531bd8bfcc9f3b.jpg";
import Swal from "sweetalert2";
import { org } from "authentication/APIData";
import { APIData } from "authentication/APIData";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import { Tooltip } from "@mui/material";
import SoftButton from "components/SoftButton";
import { useNavigate } from "react-router-dom";

function Header() {
  const [profileInfo, setProfileData] = useState({});
  const [userdata, setUserData] = useState();
  const [open, setOpen] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [orgLogo, setOrgLogo] = useState();
  const [orgShortName, setOrgShortName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const userData_Local = JSON.parse(localStorage.getItem("sessiondetails"));
    setUserData(userData_Local);
  }, []);

  useEffect(() => {
    const fetchOrgData = async () => {
      try {
        const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`);
        if (!response.ok) throw new Error("Failed to fetch organization data");
        const data = await response.json();
        setOrgLogo(data.orgLogo);
        setOrgShortName(data.orgName);
      } catch (error) {
        console.error("Error fetching organization data:", error);
      }
    };
    fetchOrgData();
  }, [org]);

  useEffect(() => {
    if (userdata) {
      fetchProfileData();
    }
  }, [userdata]);

  const fetchProfileData = async () => {
    try {
      let url;
      if (userdata.userType === "EMPLOYEE") {
        url = `${APIData.api}employee/${userdata.userName}`;
        const response = await fetch(url, { headers: APIData.headers });
        const data = await response.json();
        setProfileData(data);
      } else if (userdata.userType === "STUDENT") {
        url = `${APIData.api}studentadmissiondetails/email?email=${userdata.email}`;
        const response = await fetch(url, { headers: APIData.headers });
        const data = await response.json();
        if (data && data.length > 0) {
          setProfileData(data[0]);
        }
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: `Failed to fetch "${org}" details`,
        icon: "error",
        confirmButtonText: "OK",
      });
    }
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClickEditOpen = () => setOpenEditDialog(true);
  const handleEditClose = () => setOpenEditDialog(false);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.size > 1024 * 1024) {
        setOpenEditDialog(false);
        setOpen(false);
        setImagePreview(null);
        setSelectedFile(null);
        fetchProfileData();
        Swal.fire({
          title: "Error!",
          text: "Image size should be less than 1MB",
          icon: "error",
          confirmButtonText: "OK",
        });
        return;
      }
      setSelectedFile(file);
      setImagePreview(URL.createObjectURL(file));
    }
  };

  const handleSaveImage = async () => {
    if (!selectedFile) {
      Swal.fire({
        title: "Error!",
        text: "No file selected",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    setIsLoading(true);
    const formData = new FormData();

    try {
      let response;
      if (userdata.userType === "EMPLOYEE") {
        formData.append("id", profileInfo.employee_id);
        formData.append("file", selectedFile);
        response = await fetch(`${APIData.api}employee/${profileInfo.employee_id}/image`, {
          method: "POST",
          headers: APIData.headers,
          body: formData,
        });
      } else if (userdata.userType === "STUDENT") {
        formData.append("email", userdata.userName);
        formData.append("file", selectedFile);
        response = await fetch(`${APIData.api}studentadmissiondetails/update-image/${userdata.email}`, {
          method: "PUT",
          headers: APIData.headers,
          body: formData,
        });
      }

      const data = await response.json();

      if (response.ok) {
        setOpenEditDialog(false);
        setOpen(false);
        setImagePreview(null);
        setSelectedFile(null);
        fetchProfileData();
        Swal.fire({
          title: "Success!",
          text: "Profile image updated successfully",
          icon: "success",
          confirmButtonText: "OK",
        });
      } else {
        throw new Error(data.message || "Failed to update image");
      }
    } catch (error) {
      Swal.fire({
        title: "Error!",
        text: error.message || "Something went wrong, please try again",
        icon: "error",
        confirmButtonText: "OK",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangePasswordClick = () => {
    navigate("/changePassword");
  };

  const handleCancelEdit = () => {
    setSelectedFile(null);
    setImagePreview(null);
    setOpenEditDialog(false);
  };

  const getProfileImage = () => {
    if (userdata?.userType === "STUDENT") {
      return profileInfo.image_url;
    }
    return profileInfo.photoUrl ||
      (profileInfo.gender === "Male"
        ? maleProfileImage
        : profileInfo.gender === "Female"
          ? femaleProfileImage
          : undisclosedProfileImage);
  };

  const getDisplayName = () => {
    const name = userdata?.userType === "STUDENT" ? profileInfo.name : profileInfo.name;
    return name
      ? name
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(" ")
      : "Name";
  };

  const getRole = () => {
    return userdata?.userType === "STUDENT" 
      ? `${profileInfo.studentClass} - ${profileInfo.syllabusType}`
      : profileInfo.emy_type;
  };

  return (
    <SoftBox position="relative">
      <DashboardNavbar absolute light />
      <SoftBox
        display="flex"
        alignItems="center"
        position="relative"
        minHeight="18.75rem"
        borderRadius="xl"
        sx={{
          backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.info.main, 0.6),
              rgba(gradients.info.state, 0.6)
            )}, url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "50%",
          overflow: "hidden",
        }}
      >
        <SoftBox
          sx={{
            display: 'flex',
            alignItems: 'center',
            position: "absolute",
            top: "40px",
            left: "40px",
            zIndex: 1,
          }}
        >
          <SoftBox
            component="img"
            src={orgLogo}
            alt="Logo"
            sx={{
              width: "150px",
              height: "150px",
              marginRight: "10px",
            }}
          />
          {orgShortName && (
            <SoftTypography
              variant="h6"
              fontWeight="medium"
              color="dark"
              sx={{
                fontSize: '2rem',
              }}
            >
              {orgShortName}
            </SoftTypography>
          )}
        </SoftBox>
      </SoftBox>

      <Card
        sx={{
          backdropFilter: `saturate(200%) blur(30px)`,
          backgroundColor: ({ functions: { rgba }, palette: { white } }) => rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { navbarBoxShadow } }) => navbarBoxShadow,
          position: "relative",
          mt: -8,
          mx: 3,
          py: 2,
          px: 2,
        }}
      >
        <Grid container spacing={3} alignItems="center" justifyContent="space-between">
          <Grid item>
            <SoftAvatar
              src={getProfileImage()}
              alt="profile-image"
              variant="rounded"
              size="xl"
              shadow="sm"
              sx={{ objectFit: "contain", cursor: "pointer" }}
              onClick={handleClickOpen}
            />
          </Grid>
          <Grid item>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftTypography variant="h5" fontWeight="medium">
                {getDisplayName()}
              </SoftTypography>
              <SoftTypography variant="button" color="text" fontWeight="medium">
                {getRole()}
              </SoftTypography>
            </SoftBox>
          </Grid>
          <Grid item sx={{ ml: "auto" }}>
            <SoftBox height="100%" mt={0.5} lineHeight={1}>
              <SoftButton
                variant="gradient"
                color="info"
                onClick={handleChangePasswordClick}
                disabled={isLoading}
              >
                Change Password?
              </SoftButton>
            </SoftBox>
          </Grid>
        </Grid>
      </Card>

      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogContent sx={{ p: 0 }}>
          <img
            src={getProfileImage()}
            alt="Full-size profile"
            style={{ width: "100%", height: "auto" }}
          />
          <Tooltip title="Edit Profile Picture" arrow>
            <IconButton
              onClick={handleClickEditOpen}
              sx={{
                position: "absolute",
                top: 8,
                right: 8,
                backgroundColor: "white",
                "&:hover": {
                  backgroundColor: "lightgray",
                },
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </DialogContent>
      </Dialog>

      <Dialog open={openEditDialog} onClose={handleEditClose} maxWidth="sm">
        <DialogContent sx={{ p: 3 }}>
          <SoftBox mb={2} display="flex" flexDirection="column" alignItems="center">
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="upload-image"
              type="file"
              onChange={handleFileChange}
            />
            <label htmlFor="upload-image">
              <SoftButton variant="contained" component="span">
                Upload Image
              </SoftButton>
            </label>
            {imagePreview && <img src={imagePreview} alt="Preview" style={{ width: "60%", height: "60%" }} />}
          </SoftBox>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            <Grid item>
              <SoftButton variant="gradient" color="secondary" onClick={handleCancelEdit}>Cancel</SoftButton>
            </Grid>
            <Grid item>
              <SoftButton variant="gradient" color="success" onClick={handleSaveImage} disabled={isLoading}>
                {isLoading ? "Saving..." : "Save"}
              </SoftButton>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </SoftBox>
  );
}

export default Header;