// import SoftButton from "components/SoftButton";

// export default function Hero() {
//   return (
//     <section
//       style={{
//         padding: "100px 0",
//         background: "linear-gradient(to bottom right, #f4e1ff, #e0f2ff)"
//       }}
//     >
//       <div style={{ maxWidth: "1200px", margin: "0 auto", padding: "0 20px" }}>
//         <div
//           style={{
//             display: "flex",
//             flexWrap: "wrap",
//             alignItems: "center",
//             justifyContent: "space-between",
//             gap: "40px"
//           }}
//         >
//           <div style={{ textAlign: "center", flex: 1, maxWidth: "600px" }}>
//             <h1 style={{ fontSize: "48px", fontWeight: "bold", marginBottom: "20px" }}>
//               Smart ERP Solutions for Your Business
//             </h1>
//             <p style={{ fontSize: "18px", color: "#555", marginBottom: "30px" }}>
//               Streamline your operations, boost productivity, and make data-driven decisions with our comprehensive ERP system.
//             </p>
//             <div style={{ display: "flex", gap: "15px", justifyContent: "center" }}>
//               <SoftButton
//                 style={{
//                   background: "linear-gradient(to right, #6b46c1, #3182ce)",
//                   color: "white",
//                   padding: "12px 24px",
//                   border: "none",
//                   cursor: "pointer",
//                   fontSize: "16px",
//                   borderRadius: "5px"
//                 }}
//               >
//                 Start Free Trial
//               </SoftButton>
//               <SoftButton
//                 style={{
//                   background: "transparent",
//                   border: "2px solid #6b46c1",
//                   color: "#6b46c1",
//                   padding: "12px 24px",
//                   cursor: "pointer",
//                   fontSize: "16px",
//                   borderRadius: "5px"
//                 }}
//               >
//                 Watch Demo
//               </SoftButton>
//             </div>
//           </div>
//           <div style={{ position: "relative", flex: 1, maxWidth: "600px" }}>
//             <div
//               style={{
//                 position: "absolute",
//                 top: 0,
//                 left: 0,
//                 width: "100%",
//                 height: "100%",
//                 background: "linear-gradient(to right, #d3b8ff, #b3e5fc)",
//                 borderRadius: "20px",
//                 transform: "rotate(3deg)",
//                 opacity: 0.3
//               }}
//             ></div>
//             <img
//               src="https://sjc.microlink.io/z153cvZjmm5l5wzqGuVXnAWGQQMKfEIJMLP6F22nAJMPhozAqKvkLw2REe62F6aJX0skAoecayfKjB6fkv1CEQ.jpeg"
//               alt="ERP Dashboard"
//               style={{
//                 width: "100%",
//                 borderRadius: "20px",
//                 boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
//                 position: "relative"
//               }}
//             />
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// import { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import SoftButton from "components/SoftButton";
// import { FaPlay } from "react-icons/fa";
// import { APIData } from "authentication/APIData";

// export default function Hero({ org }) {
//   const [orgDetails, setOrgDetails] = useState({
//     orgName: "",
//     orgAddress: "",
//     logoUrl: "",
//     idCardTemplate: "1",
//   });

//   useEffect(() => {
//     const fetchOrgData = async () => {
//       try {
//         const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`, {
//           headers: APIData.headers,
//         });
//         const orgData = await response.json();
//         setOrgDetails({
//           orgName: orgData.orgName || "",
//           orgAddress: orgData.orgAddress || "",
//           logoUrl: orgData.orgLogo || "",
//           idCardTemplate: orgData.idCardTemplate || "1",
//         });
//         console.log(orgData.logoUrl)
//       } catch (error) {
//         console.error("Error fetching organization data:", error);
//       }
//     };

//     fetchOrgData();
//   }, [org]);

//   return (
//     <section
//       style={{
//         height: "100vh",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "space-between",
//         background: "linear-gradient(to bottom right, #f4e1ff, #e0f2ff)",
//         padding: "0 10%",
//       }}
//     >
//       <div style={{ flex: 1, maxWidth: "600px", textAlign: "left" }}>
//         <h1 style={{ fontSize: "56px", fontWeight: "bold", marginBottom: "20px", fontFamily: "Arial, sans-serif", fontStyle: "italic", color: "black" }}>
//           Smart ERP Solutions for Your Business
//         </h1>
//         <p style={{ fontSize: "18px", color: "#555", marginBottom: "30px", fontStyle: "italic" }}>
//           Streamline your operations, boost productivity, and make data-driven decisions with our comprehensive ERP system.
//           <span
//   style={{
//     content: "''",
//     display: "block",
//     height: "7px",
//     background: "linear-gradient(to right,rgb(123, 70, 247), #f4e1ff)", // Gradient line
//     marginTop: "10px", // Space between text and line
//     width: "50%", // Increase width if needed
//     margin: "7px auto 0", // Center the line with adjusted margin
//     marginLeft: "-1px", // Shifts the line to the left (adjust as needed)
//   }}
// ></span>

//         </p>
//         <div style={{ display: "flex", gap: "15px" }}>
//           <SoftButton
//             style={{
//               background: "linear-gradient(to right, #6b46c1, #3182ce)",
//               color: "white",
//               padding: "12px 24px",
//               border: "none",
//               cursor: "pointer",
//               fontSize: "16px",
//               borderRadius: "5px",
//               boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
//               transition: "all 0.3s ease",
//             }}
//             onMouseOver={(e) => (e.target.style.background = "linear-gradient(to right, #5a3bbf, #276baf)")}
//             onMouseOut={(e) => (e.target.style.background = "linear-gradient(to right, #6b46c1, #3182ce)")}
//           >
//             Start Free Trial
//           </SoftButton>
//           <SoftButton
//             style={{
//               background: "transparent",
//               border: "2px solid #6b46c1",
//               color: "#6b46c1",
//               padding: "12px 24px",
//               cursor: "pointer",
//               fontSize: "16px",
//               borderRadius: "5px",
//               boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
//               display: "flex",
//               alignItems: "center",
//               gap: "8px",
//               transition: "all 0.3s ease",
//             }}
//             onMouseOver={(e) => {
//               e.target.style.background = "#6b46c1";
//               e.target.style.color = "white";
//             }}
//             onMouseOut={(e) => {
//               e.target.style.background = "transparent";
//               e.target.style.color = "#6b46c1";
//             }}
//           >
//             <FaPlay /> Watch Demo
//           </SoftButton>
//         </div>
//       </div>
//       <div style={{ flex: 1, maxWidth: "600px", textAlign: "right" }}>
//         {orgDetails.logoUrl ? (
//           <img
//             src={orgDetails.logoUrl}
//             alt="Organization Logo"
//             style={{
//               width: "100%",
//               borderRadius: "20px",
//               boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
//             }}
//           />
//         ) : (
//           <p style={{ textAlign: "center", fontSize: "18px", color: "#555" }}>
//             Logo not available
//           </p>
//         )}
//       </div>
//     </section>
//   );
// }

// Hero.propTypes = {
//   org: PropTypes.string.isRequired,
// };

// import { useState, useEffect } from "react";
// import PropTypes from "prop-types";
// import SoftButton from "components/SoftButton";
// import { FaPlay, FaEdit, FaSave, FaTimes } from "react-icons/fa";
// import { APIData } from "authentication/APIData";
// import { org } from "authentication/APIData";
// import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
// import PageLayout from "examples/LayoutContainers/PageLayout";

// export default function Hero() {
//   const [isEditing, setIsEditing] = useState(false);
//   const [orgDetails, setOrgDetails] = useState({
//     orgName: "",
//     orgAddress: "",
//     logoUrl: "",
//     idCardTemplate: "1",
//   });

//   const [landingData, setLandingData] = useState({
//     header: "",
//     headerDescription: "",
//     landingImage: "",
//     feature: [],
//     featureHeader: "",
//     featureHeaderDesc: "",
//     latestUpdates: [],
//     createdBy: "",
//     createdDateTime: "",
//   });

//   const [editedData, setEditedData] = useState({
//     header: "",
//     headerDescription: "",
//   });

//   useEffect(() => {
//     const fetchOrgData = async () => {
//       try {
//         const response = await fetch(`${APIData.api}org-mdm/org-id?orgId=${org}`, {
//           headers: APIData.headers,
//         });
//         const orgData = await response.json();
//         setOrgDetails({
//           orgName: orgData.orgName || "",
//           orgAddress: orgData.orgAddress || "",
//           logoUrl: orgData.orgLogo || "",
//           idCardTemplate: orgData.idCardTemplate || "1",
//         });
//       } catch (error) {
//         console.error("Error fetching organization data:", error);
//       }
//     };

//     fetchOrgData();
//   }, [org]);

//   useEffect(() => {
//     const fetchLandingData = async () => {
//       try {
//         const response = await fetch(`${APIData.api}landing-page/org/${org}`, {
//           headers: APIData.headers,
//         });
//         const data = await response.json();
//         setLandingData(data);
//         setEditedData({
//           header: data.header || "",
//           headerDescription: data.headerDescription || "",
//           landingImage: data.landingImage || "",
//           feature: data.feature || [],
//           featureHeader: data.featureHeader || "",
//           featureHeaderDesc: data.featureHeaderDesc || "",
//           latestUpdates: data.latestUpdates || [],
//           createdBy: data.createdBy || "",
//           createdDateTime: data.createdDateTime || "",
//         });
//       } catch (error) {
//         console.error("Error fetching landing page data:", error);
//       }
//     };

//     fetchLandingData();
//   }, [org]);

//   const handleSave = async () => {
//     const currentDateTime = new Date().toISOString();
//     const payload = {
//       header: editedData.header,
//       headerDescription: editedData.headerDescription,
//       org: org,
//       updatedBy: "SYSTEM",
//       createdBy: landingData.createdBy || "SUPERADMIN",
//       updatedDateTime: currentDateTime,
//       createdDateTime: landingData.createdDateTime || currentDateTime,
//       feature: landingData.feature || [],
//       featureHeader: landingData.featureHeader || "",
//       featureHeaderDesc: landingData.featureHeaderDesc || "",
//       landingImage: landingData.landingImage || "",
//       latestUpdates: landingData.latestUpdates || []
//     };

//     try {
//       const response = await fetch(`${APIData.api}landing-page/Add`, {
//         method: "POST",
//         headers: {
//           ...APIData.headers,
//           "Content-Type": "application/json",
//         },
//         body: JSON.stringify(payload),
//       });

//       if (response.ok) {
//         setLandingData(prev => ({
//           ...prev,
//           header: editedData.header,
//           headerDescription: editedData.headerDescription
//         }));
//         setIsEditing(false);
//       } else {
//         console.error("Error saving data");
//       }
//     } catch (error) {
//       console.error("Error saving landing page data:", error);
//     }
//   };

//   const editableStyles = {
//     input: {
//       background: "transparent",
//       border: "none",
//       fontSize: "inherit",
//       fontWeight: "inherit",
//       fontStyle: "inherit",
//       fontFamily: "inherit",
//       color: "inherit",
//       width: "100%",
//       padding: "5px",
//       outline: isEditing ? "2px solid #6b46c1" : "none",
//     },
//     textarea: {
//       background: "transparent",
//       border: "none",
//       fontSize: "inherit",
//       fontStyle: "inherit",
//       color: "inherit",
//       width: "100%",
//       padding: "5px",
//       resize: "vertical",
//       minHeight: "100px",
//       outline: isEditing ? "2px solid #6b46c1" : "none",
//     }
//   };

//   return (
//     <PageLayout>
//     <section
//       style={{
//         height: "100vh",
//         display: "flex",
//         alignItems: "center",
//         justifyContent: "space-between",
//         background: "linear-gradient(to bottom right, #f4e1ff, #e0f2ff)",
//         padding: "0 10%",
//       }}
//     >
//       <div style={{ flex: 1, maxWidth: "600px", textAlign: "left", position: "relative" }}>
//         {!isEditing && (
//           <FaEdit 
//             onClick={() => setIsEditing(true)}
//             style={{
//               position: "absolute",
//               top: "-30px",
//               right: "0",
//               cursor: "pointer",
//               color: "#6b46c1"
//             }}
//           />
//         )}
//         {isEditing && (
//           <div style={{
//             position: "absolute",
//             top: "-30px",
//             right: "0",
//             display: "flex",
//             gap: "10px"
//           }}>
//             <FaSave 
//               onClick={handleSave}
//               style={{ cursor: "pointer", color: "#6b46c1" }}
//             />
//             <FaTimes 
//               onClick={() => {
//                 setIsEditing(false);
//                 setEditedData({
//                   header: landingData.header,
//                   headerDescription: landingData.headerDescription
//                 });
//               }}
//               style={{ cursor: "pointer", color: "#6b46c1" }}
//             />
//           </div>
//         )}
//         {isEditing ? (
//           <input
//             type="text"
//             value={editedData.header}
//             onChange={(e) => setEditedData(prev => ({ ...prev, header: e.target.value }))}
//             style={{
//               ...editableStyles.input,
//               fontSize: "56px",
//               fontWeight: "bold",
//               marginBottom: "20px",
//               fontFamily: "Arial, sans-serif",
//               fontStyle: "italic",
//             }}
//           />
//         ) : (
//           <h1 style={{ fontSize: "56px", fontWeight: "bold", marginBottom: "20px", fontFamily: "Arial, sans-serif", fontStyle: "italic", color: "black" }}>
//             {landingData.header || "No header available"}
//           </h1>
//         )}

//         {isEditing ? (
//           <textarea
//             value={editedData.headerDescription}
//             onChange={(e) => setEditedData(prev => ({ ...prev, headerDescription: e.target.value }))}
//             style={{
//               ...editableStyles.textarea,
//               fontSize: "18px",
//               color: "#555",
//               marginBottom: "30px",
//               fontStyle: "italic",
//             }}
//           />
//         ) : (
//           <p style={{ fontSize: "18px", color: "#555", marginBottom: "30px", fontStyle: "italic" }}>
//             {landingData.headerDescription || "no description available"}
//             <span
//               style={{
//                 content: "''",
//                 display: "block",
//                 height: "7px",
//                 background: "linear-gradient(to right,rgb(123, 70, 247), #f4e1ff)",
//                 marginTop: "10px",
//                 width: "50%",
//                 margin: "7px auto 0",
//                 marginLeft: "-1px",
//               }}
//             ></span>
//           </p>
//         )}

//         <div style={{ display: "flex", gap: "15px" }}>
//           <SoftButton
//             style={{
//               background: "linear-gradient(to right, #6b46c1, #3182ce)",
//               color: "white",
//               padding: "12px 24px",
//               border: "none",
//               cursor: "pointer",
//               fontSize: "16px",
//               borderRadius: "5px",
//               boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
//               transition: "all 0.3s ease",
//             }}
//             onMouseOver={(e) => (e.target.style.background = "linear-gradient(to right, #5a3bbf, #276baf)")}
//             onMouseOut={(e) => (e.target.style.background = "linear-gradient(to right, #6b46c1, #3182ce)")}
//           >
//             Start Free Trial
//           </SoftButton>
//           <SoftButton
//             style={{
//               background: "transparent",
//               border: "2px solid #6b46c1",
//               color: "#6b46c1",
//               padding: "12px 24px",
//               cursor: "pointer",
//               fontSize: "16px",
//               borderRadius: "5px",
//               boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
//               display: "flex",
//               alignItems: "center",
//               gap: "8px",
//               transition: "all 0.3s ease",
//             }}
//             onMouseOver={(e) => {
//               e.target.style.background = "#6b46c1";
//               e.target.style.color = "white";
//             }}
//             onMouseOut={(e) => {
//               e.target.style.background = "transparent";
//               e.target.style.color = "#6b46c1";
//             }}
//           >
//             <FaPlay /> Watch Demo
//           </SoftButton>
//         </div>
//       </div>
//       <div style={{ flex: 1, maxWidth: "600px", textAlign: "right" }}>
//         {orgDetails.logoUrl ? (
//           <img
//             src={orgDetails.logoUrl}
//             alt="Organization Logo"
//             style={{
//               width: "100%",
//               borderRadius: "20px",
//               boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
//             }}
//           />
//         ) : (
//           <p style={{ textAlign: "center", fontSize: "18px", color: "#555" }}>
//             Logo not available
//           </p>
//         )}
//       </div>
//     </section>
//     </PageLayout>
//   );
// }

// Hero.propTypes = {
//   org: PropTypes.string.isRequired,
// };


import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import SoftButton from "components/SoftButton";
import { FaPlay, FaEdit, FaSave, FaTimes, FaUpload } from "react-icons/fa";
import { APIData } from "authentication/APIData";
import { org } from "authentication/APIData";
import PageLayout from "examples/LayoutContainers/PageLayout";
import Swal from "sweetalert2";
import { Tooltip } from "@mui/material";
import SoftBox from "components/SoftBox";

export default function Hero() {
  const [isEditing, setIsEditing] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const [permission, setPermission] = useState(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [imageUploadLoading, setImageUploadLoading] = useState(false);

  const [landingData, setLandingData] = useState({
    header: "",
    headerDescription: "",
    landingImage: "",
    feature: [],
    featureHeader: "",
    featureHeaderDesc: "",
    latestUpdates: [],
    createdBy: "",
    createdDateTime: "",
  });

  const [editedData, setEditedData] = useState({
    header: "",
    headerDescription: "",
  });

  useEffect(() => {
    const loadUserPermissions = () => {
      const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
      const accessLevel = localStorage.getItem('Access Level');
      
      if (sessionDetails && sessionDetails.user) {
        setCurrentUser(sessionDetails.user);
        // Store userType in state to ensure it persists
        const userType = sessionDetails.userType;
        setIsSuperAdmin(userType === "SUPERADMIN");
      }
      
      if (accessLevel) {
        setPermission(parseInt(accessLevel));
      }
    };

    loadUserPermissions();
    window.addEventListener('storage', loadUserPermissions);
    
    return () => {
      window.removeEventListener('storage', loadUserPermissions);
    };
  }, []);

  const shouldShowEditButton = () => {
    if (permission === null) return false;
    
    if (permission === 1100 || permission === 1000) {
      return false;
    }
    return isSuperAdmin || permission === 1111;
  };

  useEffect(() => {
    fetchLandingData();
  }, [org]);

  // Other existing functions remain the same
  const fetchLandingData = async () => {
    try {
      const response = await fetch(`${APIData.api}landing-page/org/${org}`, {
        headers: APIData.headers,
      });
      const data = await response.json();
      setLandingData(data);
      setEditedData({
        header: data.header,
        headerDescription: data.headerDescription,
      });
    } catch (error) {
      console.error("Error fetching landing page data:", error);
    }
  };

  const validateContent = () => {
    const errors = [];
    if (editedData.header.length > 75) {
      errors.push('Header must be less than 70 characters');
    }
    if (editedData.headerDescription.length > 400) {
      errors.push('Description must be less than 400 characters');
    }
    return errors;
  };

  const handleSave = async () => {
    const errors = validateContent();
    if (errors.length > 0) {
      await Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        html: errors.join('<br>'),
        confirmButtonColor: '#6b46c1'
      });
      return;
    }

    const currentDateTime = new Date().toISOString();
    const payload = {
      ...landingData,
      header: editedData.header,
      headerDescription: editedData.headerDescription,
      org: org,
      updatedBy: currentUser || "SYSTEM",
      updatedDateTime: currentDateTime
    };

    try {
      const response = await fetch(`${APIData.api}landing-page/Add`, {
        method: "POST",
        headers: {
          ...APIData.headers,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        setLandingData(prev => ({
          ...prev,
          header: editedData.header,
          headerDescription: editedData.headerDescription,
          updatedBy: currentUser || "SYSTEM",
          updatedDateTime: currentDateTime
        }));
        setIsEditing(false);
        await Swal.fire({
          icon: 'success',
          title: 'Saved Successfully',
          timer: 1500,
          showConfirmButton: false
        });
      } else {
        await Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Failed to save changes',
          confirmButtonColor: '#6b46c1'
        });
      }
    } catch (error) {
      console.error("Error saving landing page data:", error);
      await Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'An error occurred while saving',
        confirmButtonColor: '#6b46c1'
      });
    }
  };

  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    
    // Validate file size (5MB = 5 * 1024 * 1024 bytes)
    if (file.size > 5 * 1024 * 1024) {
      await Swal.fire({
        icon: 'error',
        title: 'File Too Large',
        text: 'Image size must be less than 5MB',
        confirmButtonColor: '#6b46c1'
      });
      return;
    }

    // Validate file type
    if (!file.type.startsWith('image/')) {
      await Swal.fire({
        icon: 'error',
        title: 'Invalid File Type',
        text: 'Please upload an image file',
        confirmButtonColor: '#6b46c1'
      });
      return;
    }

    const formData = new FormData();
    formData.append('id', org);
    formData.append('landingImage', file);

    setImageUploadLoading(true);

    try {
      const response = await fetch(`${APIData.api}landing-page/${org}/upload-images`, {
        method: 'POST',
        headers: {
          ...APIData.headers,
        },
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        setLandingData(prev => ({
          ...prev,
          landingImage: data.landingImage // Assuming the API returns the new image URL
        }));

        fetchLandingData();
        await Swal.fire({
          icon: 'success',
          title: 'Image Uploaded Successfully',
          timer: 1500,
          showConfirmButton: false
        });
      } else {
        throw new Error('Failed to upload image');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      await Swal.fire({
        icon: 'error',
        title: 'Upload Failed',
        text: 'Failed to upload image. Please try again.',
        confirmButtonColor: '#6b46c1'
      });
    } finally {
      setImageUploadLoading(false);
    }
  };

  const editableStyles = {
    input: {
      background: "transparent",
      border: "none",
      fontSize: "inherit",
      fontWeight: "inherit",
      fontStyle: "inherit",
      fontFamily: "inherit",
      color: "inherit",
      width: "100%",
      padding: "5px",
      outline: isEditing ? "2px solid #6b46c1" : "none",
    },
    textarea: {
      background: "transparent",
      border: "none",
      fontSize: "inherit",
      fontStyle: "inherit",
      color: "inherit",
      width: "100%",
      padding: "5px",
      resize: "vertical",
      minHeight: "100px",
      outline: isEditing ? "2px solid #6b46c1" : "none",
    },
    characterCount: {
      fontSize: "12px",
      color: "#666",
      textAlign: "right",
      marginTop: "4px"
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const responsiveStyles = {
    section: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: windowWidth <= 768 ? "column" : "row",
      alignItems: "center",
      justifyContent: "space-between",
      background: "linear-gradient(to bottom right, #f4e1ff, #e0f2ff)",
      padding: windowWidth <= 768 ? "20px 5%" : "0 10%",
      gap: windowWidth <= 768 ? "40px" : "0",
    },
    contentContainer: {
      flex: 1,
      maxWidth: windowWidth <= 768 ? "100%" : "600px",
      textAlign: "left",
      position: "relative",
    },
    header: {
      fontSize: windowWidth <= 768 ? "36px" : "56px",
      fontWeight: "bold",
      marginBottom: "20px",
      fontFamily: "Arial, sans-serif",
      fontStyle: "italic",
      color: "black",
    },
    description: {
      fontSize: windowWidth <= 768 ? "16px" : "18px",
      color: "#555",
      marginBottom: "30px",
      fontStyle: "italic",
    },
    buttonsContainer: {
      display: "flex",
      gap: "15px",
      flexDirection: windowWidth <= 480 ? "column" : "row",
      width: "100%",
    },
    imageContainer: {
      flex: 1,
      maxWidth: windowWidth <= 768 ? "100%" : "600px",
      textAlign: windowWidth <= 768 ? "center" : "right",
      marginTop: windowWidth <= 768 ? "20px" : "0",
    },
    logo: {
      width: "100%",
      maxWidth: windowWidth <= 768 ? "300px" : "100%",
      borderRadius: "20px",
      boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
    },
  };

  return (
    <PageLayout>
      <section style={responsiveStyles.section}>
        <div style={responsiveStyles.contentContainer}>
          {shouldShowEditButton() && (
            <SoftBox
              position="absolute"
              top="-50px"
              right="0"
              display="flex"
              gap={2}
            >
              {!isEditing ? (
                <Tooltip title="Edit Content" arrow>
                  <SoftButton
                    onClick={() => {
                      fetchLandingData();
                      setIsEditing(true);
                    }}
                    startIcon={<FaEdit />}
                    color="secondary"
                    variant="gradient"
                  >
                    Edit
                  </SoftButton>
                </Tooltip>
              ) : (
                <>
                  <Tooltip title="Save Changes" arrow>
                    <SoftButton
                      onClick={handleSave}
                      startIcon={<FaSave />}
                      variant="gradient"
                      color="success"
                    >
                      Save
                    </SoftButton>
                  </Tooltip>
                  <Tooltip title="Cancel Changes" arrow>
                    <SoftButton
                      onClick={() => {
                        setIsEditing(false);
                        setEditedData({
                          header: landingData.header,
                          headerDescription: landingData.headerDescription
                        });
                      }}
                      startIcon={<FaTimes />}
                      variant="gradient"
                      color="error"
                    >
                      Cancel
                    </SoftButton>
                  </Tooltip>
                </>
              )}
            </SoftBox>
          )}

          <div>
            {isEditing ? (
              <>
                <input
                  type="text"
                  value={editedData.header}
                  onChange={(e) => setEditedData(prev => ({ ...prev, header: e.target.value }))}
                  style={{
                    ...editableStyles.input,
                    ...responsiveStyles.header
                  }}
                />
                <div style={editableStyles.characterCount}>
                  {editedData.header.length}/75 characters
                </div>
              </>
            ) : (
              <h1 style={responsiveStyles.header}>
                {landingData.header || "No header available"}
              </h1>
            )}
          </div>

          <div>
            {isEditing ? (
              <>
                <textarea
                  value={editedData.headerDescription}
                  onChange={(e) => setEditedData(prev => ({ ...prev, headerDescription: e.target.value }))}
                  style={{
                    ...editableStyles.textarea,
                    ...responsiveStyles.description
                  }}
                />
                <div style={editableStyles.characterCount}>
                  {editedData.headerDescription.length}/400 characters
                </div>
              </>
            ) : (
              <p style={responsiveStyles.description}>
                {landingData.headerDescription || "no description available"}
                <span
                  style={{
                    content: "''",
                    display: "block",
                    height: "7px",
                    background: "linear-gradient(to right,rgb(123, 70, 247), #f4e1ff)",
                    marginTop: "10px",
                    width: "50%",
                    margin: "7px auto 0",
                    marginLeft: "-1px",
                  }}
                ></span>
              </p>
            )}
          </div>

          <div style={responsiveStyles.buttonsContainer}>
            <SoftButton
              style={{
                background: "linear-gradient(to right, #6b46c1, #3182ce)",
                color: "white",
                padding: "12px 24px",
                border: "none",
                cursor: "pointer",
                fontSize: "16px",
                borderRadius: "5px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                transition: "all 0.3s ease",
                width: window.innerWidth <= 480 ? "100%" : "auto",
              }}
              onMouseOver={(e) => (e.target.style.background = "linear-gradient(to right, #5a3bbf, #276baf)")}
              onMouseOut={(e) => (e.target.style.background = "linear-gradient(to right, #6b46c1, #3182ce)")}
            >
              Start Free Trial
            </SoftButton>
            <SoftButton
              style={{
                background: "transparent",
                border: "2px solid #6b46c1",
                color: "#6b46c1",
                padding: "12px 24px",
                cursor: "pointer",
                fontSize: "16px",
                borderRadius: "5px",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                transition: "all 0.3s ease",
                width: window.innerWidth <= 480 ? "100%" : "auto",
              }}
              onMouseOver={(e) => {
                e.target.style.background = "#6b46c1";
                e.target.style.color = "white";
              }}
              onMouseOut={(e) => {
                e.target.style.background = "transparent";
                e.target.style.color = "#6b46c1";
              }}
            >
              <FaPlay /> Watch Demo
            </SoftButton>
          </div>
        </div>

        <div style={responsiveStyles.imageContainer}>
          {shouldShowEditButton() && (
            <div style={{ marginBottom: '1rem' }}>
              <input
                type="file"
                id="imageUpload"
                accept="image/*"
                onChange={handleImageUpload}
                style={{ display: 'none' }}
              />
              <Tooltip title="Upload New Image" arrow>
                <SoftButton
                  component="label"
                  htmlFor="imageUpload"
                  startIcon={<FaUpload />}
                  variant="gradient"
                  color="secondary"
                  disabled={imageUploadLoading}
                  style={{ width: windowWidth <= 480 ? '100%' : 'auto' }}
                >
                  {imageUploadLoading ? 'Uploading...' : 'Change Image'}
                </SoftButton>
              </Tooltip>
            </div>
          )}
          
          {landingData.landingImage ? (
            <img
              src={landingData.landingImage}
              alt="Landing page image"
              style={responsiveStyles.logo}
            />
          ) : (
            <p style={{ textAlign: "center", fontSize: "18px", color: "#555" }}>
              image not available
            </p>
          )}
        </div>
      </section>
    </PageLayout>
  );
}

Hero.propTypes = {
  org: PropTypes.string.isRequired,
};