import React, { useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Swal from 'sweetalert2';
import { Dialog } from '@mui/material';
import SoftBox from 'components/SoftBox';
import SoftButton from 'components/SoftButton';
import SoftTypography from 'components/SoftTypography';
import SoftInput from 'components/SoftInput';
import { APIData } from 'authentication/APIData';
import { org } from 'authentication/APIData';

const SocialMediaDialog = ({ open, onClose, onSubmit, user }) => {
    const [socialMedia, setSocialMedia] = useState({
        facebook: '',
        instagram: '',
        linkedin: '',
        twitter: '',
        youtube: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSocialMedia(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleSubmit = async () => {
        const payload = {
            ...socialMedia,
            createdBy: user?.userName || '',
            updatedBy: user?.userName || '',
            createdDate: new Date().toISOString(),
            updatedDate: new Date().toISOString(),
            org:org
        };

        try {
            await axios.post(`${APIData.api}social-media`, payload, {
                headers: APIData.headers
            });
            Swal.fire({
                icon: "success",
                title: "success",
                text: "Added the social media connections"
            });
            onSubmit();
            onClose();
        } catch (error) {
            console.error('Error adding social media:', error);
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Failed to add social media connections"
            });
        }
    };

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            PaperProps={{
                style: {
                    minHeight: '60vh',
                    maxHeight: '90vh'
                }
            }}
        >
            <SoftBox p={4}>
                <SoftTypography variant="h4" mb={4}>Add Social Media Connections</SoftTypography>
                
                <SoftBox mb={3}>
                    <SoftTypography variant="h6" mb={1}>Facebook</SoftTypography>
                    <SoftInput
                        placeholder="Facebook URL"
                        name="facebook"
                        type="url"
                        value={socialMedia.facebook}
                        onChange={handleChange}
                        fullWidth
                        size="small"
                    />
                </SoftBox>

                <SoftBox mb={3}>
                    <SoftTypography variant="h6" mb={1}>Instagram</SoftTypography>
                    <SoftInput
                        placeholder="Instagram URL"
                        name="instagram"
                        type="url"
                        value={socialMedia.instagram}
                        onChange={handleChange}
                        fullWidth
                        size="small"
                    />
                </SoftBox>

                <SoftBox mb={3}>
                    <SoftTypography variant="h6" mb={1}>LinkedIn</SoftTypography>
                    <SoftInput
                        placeholder="LinkedIn URL"
                        name="linkedin"
                        type="url"
                        value={socialMedia.linkedin}
                        onChange={handleChange}
                        fullWidth
                        size="small"
                    />
                </SoftBox>

                <SoftBox mb={3}>
                    <SoftTypography variant="h6" mb={1}>Twitter</SoftTypography>
                    <SoftInput
                        placeholder="Twitter URL"
                        name="twitter"
                        type="url"
                        value={socialMedia.twitter}
                        onChange={handleChange}
                        fullWidth
                        size="small"
                    />
                </SoftBox>

                <SoftBox mb={4}>
                    <SoftTypography variant="h6" mb={1}>YouTube</SoftTypography>
                    <SoftInput
                        placeholder="YouTube URL"
                        name="youtube"
                        type="url"
                        value={socialMedia.youtube}
                        onChange={handleChange}
                        fullWidth
                        size="small"
                    />
                </SoftBox>

                <SoftBox display="flex" justifyContent="flex-end" gap={3}>
                    <SoftButton onClick={onClose} variant="text" color="dark" size="small">
                        Cancel
                    </SoftButton>
                    <SoftButton onClick={handleSubmit} variant="gradient" color="info" size="small">
                        Submit
                    </SoftButton>
                </SoftBox>
            </SoftBox>
        </Dialog>
    );
};

SocialMediaDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    user: PropTypes.shape({
        userName: PropTypes.string
    }).isRequired,
    org: PropTypes.string.isRequired
};

export default SocialMediaDialog;