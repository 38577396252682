import { useEffect, useState } from "react";
import { APIData } from "authentication/APIData"; // Import APIData
import axios from "axios";
import { org } from "authentication/APIData"; // Assuming `org` is your orgId
import Header from "./Mheader";
import Footers from "./Footers";

export default function AboutUs() {
  const [orgDetails, setOrgDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const orgId = org; // Get orgId dynamically from APIData
    console.log("Fetching data for orgId:", orgId); // Log orgId to verify it's correct

    if (orgId) {
      // Fetch organization details using the provided API
      axios
        .get(`${APIData.api}org-mdm/org-id?orgId=${orgId}`, {
          headers: APIData.headers, // Using the headers from APIData for authorization
        })
        .then((response) => {
          console.log("API Response Data:", response.data); // Log the response data
          setOrgDetails(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching data:", error); // Log any fetch errors
          setError("Failed to fetch organization details");
          setLoading(false);
        });
    } else {
      console.error("No orgId found in APIData");
      setError("Organization ID not found");
      setLoading(false);
    }
  }, []); // Empty array to run only once when the component mounts

  if (loading) return <p style={{ textAlign: "center", color: "gray" }}>Loading...</p>;
  if (error) return <p style={{ textAlign: "center", color: "red" }}>{error}</p>;

  return (<>
    <Header/>
    <div style={{ maxWidth: "600px", margin: "0 auto", padding: "20px", background: "#fff", borderRadius: "10px", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" ,marginTop:"100px",marginBottom:"88px"}}>
      <h1 style={{ textAlign: "center", color: "#007BFF", fontSize: "24px", marginBottom: "10px" }}>About Us</h1>
      {orgDetails ? (
          <div>
          {/* Display the organization logo */}
          <img
            src={orgDetails.orgLogo}
            alt={orgDetails.orgName}
            style={{ width: "100px", height: "auto", margin: "0 auto", display: "block", borderRadius: "10px" }}
            />
          {/* Display the organization name */}
          <h2 style={{ fontSize: "20px", fontWeight: "bold", color: "#333", textAlign: "center" }}>
            {orgDetails.orgName || "Organization Name"}
          </h2>
          {/* Display the organization's description or additional details */}
          <div style={{ textAlign: "center", color: "#666", marginTop: "10px" }}>
            <p>{orgDetails.about.join(" ") || "No description available."}</p>
          </div>
        </div>
      ) : (
        <p style={{ textAlign: "center", color: "gray" }}>No details available.</p>
      )}
    </div>
    <Footers/>
            </>
  );
}
