// import React from "react";

// const containerStyle = {
//   maxWidth: "900px",
//   margin: "0 auto",
//   padding: "40px 20px",
//   textAlign: "center",
// };

// const gridStyle = {
//   display: "grid",
//   gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr))",
//   gap: "20px",
// };

// const cardStyle = {
//   backgroundColor: "#f9f9f9",
//   padding: "20px",
//   borderRadius: "8px",
//   boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
//   textAlign: "center",
// };

// const quoteStyle = {
//   color: "#666",
//   fontStyle: "italic",
//   marginBottom: "12px",
// };

// const authorStyle = {
//   fontWeight: "bold",
//   marginBottom: "4px",
// };

// const companyStyle = {
//   fontSize: "14px",
//   color: "#777",
// };

// const testimonials = [
//   {
//     quote: "StreamLine has revolutionized how we manage our projects. It is intuitive and powerful!",
//     author: "Jane Doe",
//     company: "Tech Innovators Inc.",
//   },
//   {
//     quote: "The analytics feature alone has saved us countless hours. Highly recommended!",
//     author: "John Smith",
//     company: "Creative Solutions LLC",
//   },
//   {
//     quote: "StreamLine's customer support is top-notch. They are always there when we need them.",
//     author: "Emily Brown",
//     company: "Global Enterprises",
//   },
// ];

// export default function Testimonials() {
//   return (
//     <section id="testimonials" style={{ backgroundColor: "white", padding: "40px 0" }}>
//       <div style={containerStyle}>
//         <h2 style={{ fontSize: "28px", fontWeight: "bold", marginBottom: "24px", color: "#333" }}>
//           What Our Customers Say
//         </h2>
//         <div style={gridStyle}>
//           {testimonials.map((testimonial, index) => (
//             <div key={index} style={cardStyle}>
//               <p style={quoteStyle}>{testimonial.quote}</p>
//               <div>
//                 <p style={authorStyle}>{testimonial.author}</p>
//                 <p style={companyStyle}>{testimonial.company}</p>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </section>
//   );
// }

import React, { useState, useEffect } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";

const containerStyle = {
  maxWidth: "900px",
  margin: "0 auto",
  padding: "40px 20px",
  textAlign: "center",
};

const gridStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
  height: "250px", 
};

const cardStyle = {
  position: "absolute",
  width: "100%",
  maxWidth: "400px",
  padding: "20px",
  borderRadius: "8px",
  backgroundColor: "#f9f9f9",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  textAlign: "center",
  transition: "all 0.5s ease-out",
};

const quoteStyle = {
  color: "#666",
  fontStyle: "italic",
  marginBottom: "12px",
};

const authorStyle = {
  fontWeight: "bold",
  marginBottom: "4px",
};

const companyStyle = {
  fontSize: "14px",
  color: "#777",
};

const arrowStyle = {
  position: "absolute",
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "rgba(255, 255, 255, 0.8)",
  border: "none",
  fontSize: "24px",
  cursor: "pointer",
  padding: "10px",
  borderRadius: "50%",
  color: "#333",
  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
  zIndex: 10,
};

const leftArrowStyle = { ...arrowStyle, left: "10px" };
const rightArrowStyle = { ...arrowStyle, right: "10px" };

const testimonials = [
  {
    quote: "StreamLine has revolutionized how we manage our projects. It is intuitive and powerful!",
    author: "Jane Doe",
    company: "Tech Innovators Inc.",
  },
  {
    quote: "The analytics feature alone has saved us countless hours. Highly recommended!",
    author: "John Smith",
    company: "Creative Solutions LLC",
  },
  {
    quote: "StreamLine's customer support is top-notch. They are always there when we need them.",
    author: "Emily Brown",
    company: "Global Enterprises",
  },
];

export default function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1));
    }, 3000);
    return () => clearInterval(interval);
  }, [currentIndex]);

  const getCardStyle = (index) => {
    const position = index - currentIndex;
    let style = { ...cardStyle };

    if (position === 0) {
      style.transform = "translateX(0) scale(1)";
      style.opacity = 1;
      style.zIndex = 2;
    } else if (position === 1 || position === -testimonials.length + 1) {
      style.transform = "translateX(100%) scale(0.85)";
      style.opacity = 0.7;
      style.zIndex = 1;
    } else if (position === -1 || position === testimonials.length - 1) {
      style.transform = "translateX(-100%) scale(0.85)";
      style.opacity = 0.7;
      style.zIndex = 1;
    } else {
      style.opacity = 0;
      style.transform = "translateX(0) scale(0.6)";
    }
    return style;
  };

  return (
    <section id="testimonials" style={{ backgroundColor: "white", padding: "40px 0" }}>
      <div style={containerStyle}>
        <h2 style={{ fontSize: "28px", fontWeight: "bold", marginBottom: "24px", color: "#333" }}>
          Happy Clients <span style={{ color: "blue", fontStyle: "italic" }}>Feedback</span>
        </h2>
        <div style={gridStyle}>
          <button onClick={() => setCurrentIndex((prevIndex) => (prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1))} style={leftArrowStyle}>
            <ChevronLeft size={24} />
          </button>

          {testimonials.map((testimonial, index) => (
            <div key={index} style={getCardStyle(index)}>
              <p style={quoteStyle}>{testimonial.quote}</p>
              <div>
                <p style={authorStyle}>{testimonial.author}</p>
                <p style={companyStyle}>{testimonial.company}</p>
              </div>
            </div>
          ))}

          <button onClick={() => setCurrentIndex((prevIndex) => (prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1))} style={rightArrowStyle}>
            <ChevronRight size={24} />
          </button>
        </div>
      </div>
    </section>
  );
}