import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Backdrop,
    CircularProgress,
    Card,
    DialogActions,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    Tooltip,
    Switch,
    FormControlLabel
} from '@mui/material';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import Swal from 'sweetalert2';
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout';
import DashboardNavbar from 'examples/Navbars/DashboardNavbar';
import Table from "examples/Tables/Table";
import SoftTypography from 'components/SoftTypography';
import SoftBox from 'components/SoftBox';
import SoftAvatar from 'components/SoftAvatar';
import { APIData, org } from 'authentication/APIData';
import SoftInput from 'components/SoftInput';
import SoftButton from 'components/SoftButton';
import { useNavigate } from 'react-router-dom';

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const [sortField, setSortField] = useState('');
    const [sortDirection, setSortDirection] = useState('asc');
    const [page, setPage] = useState(0);
    const [itemsPerPage, setItemsPerPage] = useState(5);
    const [selectedBlog, setSelectedBlog] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(true);
    const [contentModalOpen, setContentModalOpen] = useState(false);
    const [selectedContent, setSelectedContent] = useState('');
    const [currentUser, setCurrentUser] = useState(null);
    const [permission, setPermission] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    const [editForm, setEditForm] = useState({
        title: '',
        content: '',
        author: '',
        published: false
    });

    const [errors, setErrors] = useState({
        title: '',
        content: '',
        author: ''
    });

    const navigate = useNavigate();

    useEffect(() => {
        const sessionDetails = JSON.parse(localStorage.getItem("sessiondetails"));
        if (sessionDetails && sessionDetails.user) {
            setCurrentUser(sessionDetails.user);
            setIsSuperAdmin(sessionDetails.userType === "SUPERADMIN");
        }
        const accessLevel = localStorage.getItem('Access Level');
        setPermission(accessLevel);
    }, []);

    useEffect(() => {
        fetchData();
    }, [org]);

    const fetchData = async () => {
        try {
            const url = `${APIData.api}blog/details/org/${org}`;
            const response = await fetch(url, { headers: APIData.headers });
            if (response.ok) {
                const data = await response.json();
                setBlogs(data);
                setLoading(false);
            } else {
                Swal.fire('Error', 'Failed to fetch blogs', 'error');
                setLoading(false);
            }
        } catch (error) {
            Swal.fire('Error', 'Error fetching blogs', 'error');
            setLoading(false);
        }
    };

    const handleSort = (field) => {
        let direction = "asc";
        if (sortField === field && sortDirection === "asc") {
            direction = "desc";
        }
        setSortField(field);
        setSortDirection(direction);
    };

    const handleUpdate = (blog) => {
        setIsDialogOpen(true);
        setSelectedBlog(blog);
        setEditForm({
            title: blog.title,
            content: blog.content,
            author: blog.author,
            published: blog.published
        });
    };

    const validateForm = () => {
        const newErrors = {};
        let isValid = true;
        if (!editForm.title.trim()) {
            newErrors.title = 'Title is required';
            isValid = false;
        }
        if (!editForm.content.trim()) {
            newErrors.content = 'Content is required';
            isValid = false;
        }
        if (!editForm.author.trim()) {
            newErrors.author = 'Author is required';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleEditFormChange = (e) => {
        const { name, value, checked } = e.target;
        setEditForm((prev) => ({
            ...prev,
            [name]: name === 'published' ? checked : value,
        }));
    };

    const handleSave = () => {
        if (validateForm()) {
            handleUpdateBlog(editForm);
            setIsDialogOpen(false);
        }
    };

    const handleUpdateBlog = async () => {
        setIsDialogOpen(false);
        const form = {
            id: selectedBlog.id,  // Ensuring we pass the correct id
            title: editForm.title,
            content: editForm.content,
            author: editForm.author,
            published: editForm.published,
            publishedDate: selectedBlog.publishedDate,  // Preserve the original published date
            featuredImage: selectedBlog.featuredImage,  // Preserve the featured image
            createdBy: selectedBlog.createdBy,         // Preserve the creator
            updatedBy: currentUser || selectedBlog.updatedBy,  // Update the modifier
            org: selectedBlog.org
        };

        const url = `${APIData.api}blog/details`;
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...APIData.headers
                },
                body: JSON.stringify(form),
            });

            if (response.ok) {
                Swal.fire('Success', 'Updated successfully', 'success');
                fetchData();
            } else {
                Swal.fire('Error', 'Failed to update', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'An error occurred during the update', 'error');
        }
    };

    const handlePublishToggle = async (blog) => {
        const updatedBlog = {
            id: blog.id,                    // Ensure correct ID is passed
            title: blog.title,
            content: blog.content,
            author: blog.author,
            published: !blog.published,      // Toggle the publish status
            publishedDate: blog.publishedDate,
            featuredImage: blog.featuredImage,
            createdBy: blog.createdBy,
            updatedBy: currentUser || blog.updatedBy,  // Update the modifier
            org: blog.org
        };
        
        try {
            const url = `${APIData.api}blog/details`;
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    ...APIData.headers
                },
                body: JSON.stringify(updatedBlog),
            });

            if (response.ok) {
                Swal.fire('Success', `Blog ${updatedBlog.published ? 'published' : 'unpublished'} successfully`, 'success');
                fetchData();
            } else {
                Swal.fire('Error', 'Failed to update publish status', 'error');
            }
        } catch (error) {
            Swal.fire('Error', 'An error occurred while updating publish status', 'error');
        }
    };

    const handleDelete = (blog) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'This action cannot be undone once deleted.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'Cancel',
            reverseButtons: true,
        }).then((result) => {
            if (result.isConfirmed) {
                // Updated delete API endpoint
                const url = `${APIData.api}blog/details/org/${org}/id/${blog.id}`;
                fetch(url, {
                    method: 'DELETE',
                    headers: APIData.headers,
                })
                    .then(response => {
                        if (response.ok) {
                            Swal.fire('Success', 'Deleted successfully', 'success');
                            fetchData();
                        } else {
                            Swal.fire('Error', 'Failed to delete', 'error');
                        }
                    })
                    .catch(error => {
                        Swal.fire('Error', "An error occurred", 'error');
                    });
            }
        });
    };

    const handleNewBlogClick = () => {
        navigate("/createblog");
    };

    const filteredBlogs = blogs.filter(blog =>
        blog.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        blog.content.toLowerCase().includes(searchTerm.toLowerCase()) ||
        blog.author.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const sortedBlogs = [...filteredBlogs].sort((a, b) => {
        if (!sortField) return 0;
        const aValue = a[sortField];
        const bValue = b[sortField];
        return sortDirection === 'asc' ? (aValue > bValue ? 1 : -1) : (aValue < bValue ? 1 : -1);
    });

    const paginatedBlogs = sortedBlogs.slice(
        page * itemsPerPage,
        (page + 1) * itemsPerPage
    );

    const columns = [
        { name: "Title", align: "left" },
        { name: "Author", align: "left" },
        { name: "Content", align: "left" },
        { name: "Published", align: "center" },
        ...(permission === "1110" || permission === "1111" ? [{ name: "Action", align: "center" }] : [])
    ];

    const rows = paginatedBlogs.map((blog) => ({
        Title: (
            <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
                <SoftBox mr={2}>
                    <SoftAvatar
                        src={blog.featuredImage || `https://ui-avatars.com/api/?name=${encodeURIComponent(blog.title)}&background=random`}
                        alt={blog.title}
                        size="sm"
                        variant="rounded"
                    />
                </SoftBox>
                <SoftTypography variant="button" fontWeight="medium">
                    {blog.title}
                </SoftTypography>
            </SoftBox>
        ),
        Author: blog.author,
        Content: (
            <SoftBox>
                {blog.content.length > 50 ? (
                    <>
                        {blog.content.slice(0, 50)}...
                        <SoftButton
                            variant="text"
                            color="info"
                            size="small"
                            onClick={() => {
                                setSelectedContent(blog.content);
                                setContentModalOpen(true);
                            }}
                        >
                            Read More
                        </SoftButton>
                    </>
                ) : (
                    blog.content
                )}
            </SoftBox>
        ),
        Published: (
            <Switch
                checked={blog.published}
                onChange={() => handlePublishToggle(blog)}
                color="primary"
                disabled={!(permission === "1110" || permission === "1111" || isSuperAdmin)}
            />
        ),
        Action: (
            <div>
                {(permission === "1110" || permission === "1111" || isSuperAdmin) && (
                    <SoftTypography
                        component="span"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                            setErrors({});
                            return handleUpdate(blog);
                        }}
                    >
                        Edit
                    </SoftTypography>
                )}
                {(permission === "1111" || isSuperAdmin) && (
                    <SoftTypography
                        component="span"
                        variant="button"
                        color="error"
                        fontWeight="medium"
                        style={{ cursor: 'pointer' }}
                        sx={{ marginLeft: "10px" }}
                        onClick={() => handleDelete(blog)}
                    >
                        Delete
                    </SoftTypography>
                )}
            </div>
        ),
    }));

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <SoftBox py={3}>
                <SoftBox mb={3}>
                    <Card>
                        <SoftBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
                            <SoftTypography variant="h6">Blog List</SoftTypography>
                            <SoftBox display="flex" alignItems="center" gap={2}>
                                <SoftBox>
                                    <SoftInput
                                        placeholder="Search..."
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}
                                        size="small"
                                    />
                                </SoftBox>
                                {(permission === "1100" || permission === "1110" || permission === "1111" || isSuperAdmin) && (
                                    <Tooltip title="Add New Blog" arrow>
                                        <SoftButton variant="gradient" color="info" size="small" onClick={handleNewBlogClick}>
                                            + Add
                                        </SoftButton>
                                    </Tooltip>
                                )}
                            </SoftBox>
                        </SoftBox>

                        <SoftBox sx={{ overflow: 'auto' }}>
                            {loading ? (
                                <SoftBox display="flex" justifyContent="center" p={3}>
                                    <CircularProgress />
                                </SoftBox>
                            ) : (
                                <>
                                    <Table columns={columns} rows={rows} />
                                    <SoftBox
                                        display="flex"
                                        justifyContent="flex-end"
                                        alignItems="center"
                                        p={3}
                                    >
                                        <SoftBox display="flex" gap={1} alignItems="center">
                                            <SoftTypography variant="caption" color="secondary">
                                                {page * itemsPerPage + 1}-{Math.min((page + 1) * itemsPerPage, sortedBlogs.length)} of {sortedBlogs.length}
                                            </SoftTypography>
                                            <SoftButton
                                                variant="text"
                                                color="info"
                                                onClick={() => setPage(prev => Math.max(0, prev - 1))}
                                                disabled={page === 0}
                                                iconOnly
                                            >
                                                <KeyboardArrowLeft />
                                            </SoftButton>
                                            <SoftButton
                                                variant="text"
                                                color="info"
                                                onClick={() => setPage(prev => Math.min(Math.ceil(sortedBlogs.length / itemsPerPage) - 1, prev + 1))}
                                                disabled={page >= Math.ceil(sortedBlogs.length / itemsPerPage) - 1}
                                                iconOnly
                                            >
                                                <KeyboardArrowRight />
                                            </SoftButton>
                                        </SoftBox>
                                    </SoftBox>
                                </>
                            )}
                        </SoftBox>
                    </Card>
                </SoftBox>
            </SoftBox>

            <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)} fullWidth maxWidth="md">
                <DialogTitle>Edit Blog</DialogTitle>
                <DialogContent>
                    <Typography variant="h6">Title</Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="title"
                        value={editForm.title}
                        onChange={handleEditFormChange}
                        margin="normal"
                        error={Boolean(errors.title)}
                        helperText={errors.title}
                    />
                    <Typography variant="h6">Author</Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="author"
                        value={editForm.author}
                        onChange={handleEditFormChange}
                        margin="normal"
                        error={Boolean(errors.author)}
                        helperText={errors.author}
                    />
                    <Typography variant="h6">Content</Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        name="content"
                        value={editForm.content}
                        onChange={handleEditFormChange}
                        multiline
                        rows={4}
                        margin="normal"
                        error={Boolean(errors.content)}
                        helperText={errors.content}
                    />
                    <FormControlLabel
                        control={
                            <Switch
                                checked={editForm.published}
                                onChange={handleEditFormChange}
                                name="published"
                                color="primary"
                            />
                        }
                        label="Published"
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setIsDialogOpen(false)} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={contentModalOpen}
                onClose={() => setContentModalOpen(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Full Content</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        {selectedContent}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setContentModalOpen(false)}
                        color="primary"
                    >
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </DashboardLayout>
    );
};

BlogList.propTypes = {
    org: PropTypes.string.isRequired,
};

export default BlogList;